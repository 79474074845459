import {loadFromLS} from "app/Base/Tables/FlexibleTable/SaveLoad";
import {AtolFullReportService, AtolFullReportTransactionService} from "src/API/AtolAPI";
import {BlockingContragentService} from "src/API/BlockingAPI";
import {ContractContragentService} from "src/API/ContractAPI";
import {ContragentService} from "src/API/ContragentAPI";
import DiscountService from "src/API/Discount";
import {OfferContragentService} from "src/API/OfferAPI";
import {OrderContragentService} from "src/API/OrderAPI";
import {ProductService, ProductOfferService} from "src/API/ProductAPI";
import {RevenueContragentService} from "src/API/RevenueAPI";
import {SubscribeContragentService} from "src/API/ServiceAPI";
import useUser from "src/Providers/UserProvider";
import {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import {useToggle} from "react-use";
import {PPMailService} from "../API/PPFMailAPI";
import {EdoService, EdoMessageService} from "../API/EdoAPI";


function is_class(value) {
    return value.toString().includes("class");
}

function is_function(value) {
    return value.toString().includes("function");
}

function is_object(value) {
    return value.toString().includes("object Object");
}

/**
 * отдельный хук для запроса метадаты у вьюхи через HTTP OPTIONS
 * @param get_service
 * @param ModelName
 */
function useMetadata(get_service, ModelName) {
    return useQuery(
        [ModelName, "options"],
        async () => {
            const srv = get_service();
            try {
                const data = (await srv.options()).data || {fields: {}, attributes: []};
                const fields = data.fields;
                const keys = data.fields.map(x => x.name);

                const attributes = data.attributes.map(x => (
                    {
                        name: x.codename,
                        label: x.name,
                        type: x.type,
                        choices: x.choices,
                    }));
                const columns = Object.values(fields).map(x => (
                                          {
                                              title: x.label,
                                              dataIndex: x.name,
                                              key: x.name,
                                              type: x.type,
                                              choices: x.choices,
                                          }))
                                      .concat(attributes.map(x => (
                                          {
                                              title: x.label,
                                              dataIndex: x.name,
                                              key: x.name,
                                              type: x.type,
                                              choices: x.choices,
                                          })).filter(x => (!keys.includes(x.key))));
                const filters = data.filters;

                return {fields, attributes, columns, filters};
            } catch (err) {
                return {fields:[], attributes: [], columns: [], filters: []}
            }
        },
    ).data || {};
}

/**
 * Хук для запроса данных + метаданных из вьюхи (list + options)
 * @param Service {BaseService}
 * @param page {int}
 * @param columns {array}
 * @param filters {object}
 * @param id {int}
 * @returns {{query, options}}
 */
export function useData(Service, {page, columns, params, id, contragent}) {

    const {globalUpdate} = useUser();
    const [_update, toggleTableUpdate] = useToggle();
    const [_page, setPage] = useState(page || 1);
    const [limit, setLimit] = useState(20);
    const [_filters, _setFilters] = useState(null);
    const [_columns, _setColumns] = useState(columns);
    // const [_order, setOrder] = useState({});
    const [search, setSearch] = useState();
    const qc = useQueryClient();
    const ModelName = Service.MODEL.split("/").reverse()[0];
    const options = useMetadata(get_service, ModelName);
    const {fields, attributes, filters} = options;

    useEffect(() => {
        qc.invalidateQueries({queryKey: [ModelName]});
    }, [globalUpdate, _update, id, _filters, _columns]);

    function get_service() {
        if (is_class(Service))
            return new Service(id);
        else if (is_object(Service))
            return Service;
        else if (is_function(Service))
            return Service();
    }

    function setFilters(fltrs) {
        if (fltrs instanceof Object) {
            if ("order_by" in fltrs)
                fltrs.order_by = fltrs.order_by
                                      .filter(x => (x != "" && x != null && x != "-"));
        }
        _setFilters(fltrs);
    }

    function setColumns(columns) {
        _setColumns(columns.filter(x => x.key).map(x => x.key));
    }

    function _transform_columns() {
        const _flds = fields?.map(x => x.name || x.key)
                            ?.filter(x => _columns?.includes(x)) || _columns.filter(x => x != 'collapse');
        const _attrs = attributes?.map(x => x.name || x.key)
                                 ?.filter(x => _columns?.includes(x)) || [];

        return [_flds, _attrs];
    }

    function saveAsCSV() {
        const srv = get_service();
        const [_flds, _attrs] = _transform_columns();
        const name = loadFromLS(ModelName);

        return srv.csv(_flds, _attrs, _filters, `${ModelName}.csv`, name);
    }

    function saveAsXLSX() {
        const srv = get_service();
        const [_flds, _attrs] = _transform_columns();
        const name = loadFromLS(ModelName);

        return srv.excel(_flds, _attrs, _filters, `${ModelName}.xlsx`, name);
    }

    let query_key;
    if (contragent && contragent.id)
        query_key = [ModelName, _page, limit, search, _columns, _filters, contragent.id];
    else
        query_key = [ModelName, _page, limit, search, _columns, _filters];

    const query = useQuery(
        query_key,
        async () => {
            const srv = get_service();
            let data;
            if (_filters && (_columns || []).length > 0) {
                const [_flds, _attrs] = _transform_columns();

                data = (await srv.filter(
                    _flds, _attrs, _filters, _page, limit, search,
                )).data || {data: []};
            }
            else {
                data = (await srv.list({params: {
                    page: _page,
                    limit,
                    ...{params},
                    ...{search},
                }})).data || {data: []};
            }
            return data;
        },
        {enabled: !!fields && !!attributes && !!_columns && !!_filters},
        // {keepPreviousData: true} // под вопросом. Проверьте
    );

    return {
        query,
        options,
        page: _page, setPage,
        limit, setLimit,
        filters: _filters, setFilters,
        columns: _columns, setColumns,
        search, setSearch,
        Service,
        toggleTableUpdate,
        saveAsXLSX,
        saveAsCSV,
        ModelName,
        // setOrder
    };
}


/**
 *
 * @param page {int}
 * @param filters {object}
 * @param id {int|null}
 * @param contragent
 * @returns {{query, options}}
 */
export function useOrdersData({page, filters, id, contragent}) {
    return useData(new OrderContragentService(contragent?.id), {page, filters, id, contragent});
}


export function useContragentData(page = 0, filters = {}, id = null) {
    // return useData(ContragentService, {page, filters, id}); ТАК НЕ РАБОТАЕТ при сборке для PROD
    return useData(new ContragentService(), {page, filters, id});
}


export function useProductData(page = 0, filters = {}, id = null) {
    return useData(ProductService, {page, filters, id});
}


export function useBlockingData(contragent) {
    return useData(new BlockingContragentService(contragent.id), {contragent});
}

export function useDiscountData(contragent, page = 0, filters = {}, id = null) {
    return useData(new DiscountService(contragent.id), {page, id, filters});
}

export function useReportData(page = 0, filters = {}, id = null) {
    return useData(new AtolFullReportService(), {page, filters, id});
}

export function useReportTransactionData(page = 0, filters = {}, id = null) {
    return useData(new AtolFullReportTransactionService(), {page, filters, id});
}


export function useContractData(page = 0, contragent = null) {
    return useData(new ContractContragentService(contragent), {page, contragent});
}


export function useOfferData({page, contragent}) {
    return useData(new OfferContragentService(contragent?.id || 0), {page: page || 0, contragent});
}

export function useOfferProductData({contragent_id, product_id, page}) {
    return useData(new ProductOfferService(contragent_id, product_id), {page: page || 0});
}

export function useRevenueData({page, contragent}) {
    return useData(new RevenueContragentService(contragent?.id || 0), {page: page || 0, contragent});
}


export function useSubscribeData({page, contragent}) {
    return useData(new SubscribeContragentService(contragent?.id || 0), {page: page || 0, contragent});
}

export function usePPMailData({page, contragent}) {
    return useData(PPMailService.contragented(contragent?.id || 0), {page: page || 0, contragent});
}

export function useEdoData() {
    return useData(new EdoMessageService(), {page: 1});
}