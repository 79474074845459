import BaseService from "./BaseAPI";


export class EdoService extends BaseService {
    MODEL = "edo";

    _mk_res(part) {
        let url = `${this.API}/${this.model}`;
        if (part)
            url = url.concat(`/${part}`);
        return url;
    }

    check(url) {
        // return this._get('status').catch(EdoService.error_handler);
        return this._post("status", {url}).catch(EdoService.error_handler);
    }

    check_diadoc(url) {
        // return this._get('diadoc').catch(EdoService.error_handler);
        return this._post("diadoc", {url}).catch(EdoService.error_handler);
    }

    changeStatus(data) {
        return this._post("status").catch(EdoService.error_handler);
    }

    sign_and_send() {
        return this._get(`senddoc/${this.get_id()}`)
                   .catch(EdoService.error_handler);
    }

    get_status() {
        return this._get(`getdocstatus/${this._id}`).catch(EdoService.error_handler);
    }

    get_statuses_byuuid(uuids) {
        return this._post("getdocstatus", {uuids: uuids}).then(res => {
            console.log(res.data);
            return res;
        }).catch(EdoService.error_handler);
    }

    get_statuses(ids) {
        return this._post("getdocstatus", {ids}).then(res => {
            return res;
        }).catch(EdoService.error_handler);
    }

}

export class EdoMessageService extends BaseService {
    MODEL = "edo/messages";

    changeStatus(data) {
        return this._post("status", data).catch(EdoMessageService.error_handler);
    }

    refreshStatus() {
        return this._get("status", {params: {id: this._id}})
                   .catch(EdoMessageService.error_handler);
    }
}
