import {message, notification} from "antd";
import {ContragentService} from "app/API/ContragentAPI";
import axios from "../config/axios";
import BaseService, {error_handler} from './BaseAPI'
import {AttributableService} from "./AttributableService";


class SubscribeService extends AttributableService {
    MODEL = 'service'

    constructor(contragent_id, service_id){
        super(service_id);
        this.MODEL = `contragent/${contragent_id}/service`
    }

    getBillingList() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`billing`));
    }

    getPlan() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`plan`)).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    getDetalization() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`detalization`)).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    updateProlongationGroup(data) {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`prolongation`), data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    closeService() {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`close`)).then(res => {
            message.success("услуга успешно отменена");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    createCrossgrade(data) {
        // TODO: переделать на this._post
        return axios.post(this._mk_res(`crossgrade`), data)
                    .catch(err => {
                        error_handler(err);
                        return Promise.reject(err);
                    });
    }

    getCrossgrades() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res(`crossgrade`)).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    calcProlongDate(params) {
        return this._get("calc_prolong_date", {params: params});
    }

    manual_renewal(data) {
        return this._post("manual-renewal", data);
    }

    setPlanFactField(id, data) {
        return this._patch(`plan/${id}`, data);
    }

    getAvailRevenues() {
        return this._get("revenues");
    }

    getOrders() {
        return this._get('order');
    }
}

class SubscribeContragentService extends SubscribeService {
    MODEL = "service";

    constructor(contragent_id) {
        super(null);
        this._ctg_id = contragent_id;
        this._ctg_service = new ContragentService(contragent_id);
    }

    get model() {
        return `contragent/${this._ctg_id}/${this.MODEL}`;
    }

    list(params) {
        return super.list({params: {contragent_id: this._ctg_id, ...params}});
    }
}

class SubscribeServiceGroup extends BaseService {
    MODEL = "service/group";

    constructor(contragent_id, service_id){
        super(service_id);
        this.MODEL = `contragent/${contragent_id}/service/group`
    }

    cancel() {
        return axios.post(this._mk_res(`cancel`)).then(res => {
            message.success("Группа подписок отменена.");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    manual_renewal(data) {
        return this._post("manual-renewal", data);
    }

}

class CrossgradeService extends BaseService {
    MODEL = "crossgrade";

    constructor(contragent_id, service_id){
        super(service_id);
        this.MODEL = `contragent/${contragent_id}/crossgrade`
    }

    get() {
        // TODO: переделать на this._get
        return axios.get(this._mk_res()).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    cancel() {
        // TODO: переделать на this._post
        return axios.post(this._mk_res("cancel")).then(res => {
            notification.success({message: res.data.message});
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }
}

class CounterService extends BaseService {
    MODEL = "service/counter";

    constructor(contragent_id, service_id){
        super(service_id);
        this.MODEL = `contragent/${contragent_id}/service/counter`
    }

    addCounter(data) {
        // TODO: переделать на this._post
        return this._post('add', data).then(res => {
            message.success("значение успешно добавлено");
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });

    }

    lastValueCounter() {
        return this._get('last').then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });

    }

}


export {
    CounterService,
    SubscribeService,
    CrossgradeService,
    SubscribeServiceGroup,
    SubscribeContragentService,
};
