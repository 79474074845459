import React, {useEffect, useState} from "react";
import {CheckOutlined, CloseOutlined, EditOutlined, PoweroffOutlined, UploadOutlined} from "@ant-design/icons";
import {
    Layout, Button, notification, Input, Space, Form,
    Upload, message, Skeleton, Menu, Card, Descriptions,
    Switch as SwitchButton, DatePicker,
    Select, Modal,
    Tag, Spin,
    Radio,
    Typography,
    Table,
} from "antd";
import {Route, Switch, Redirect, Link, useLocation} from "react-router-dom";
import {SettingService} from "src/API/SysAPI";
import {NumeratorService} from "src/API/NumeratorAPI";
import {AtolPService} from "src/API/AtolAPI";
import {useForm} from "antd/es/form/Form";
import {renderInput} from "src/lib/dynamic-comps";
import moment from "moment";
import useSystemInfo from "../../Providers/SystemProvider";
import useUser from "../../Providers/UserProvider";
import useIntegration from "../../Providers/IntegrationsProvider";
import { NumeratorModal } from "./Modals/NumeratorModal";
import {EdoService} from "app/API/EdoAPI";
import { AtolImportService } from "../../API/AtolAPI";


export function Settings() {
    const {contragent} = useUser();
    let location = useLocation();

    const [menu, setMenu] = useState({});
    const [groups, setGroups] = useState([]);
    const {integrations} = useIntegration();

    const map_groups = Object.fromEntries(groups);

    useEffect(() => {
        new SettingService().list().then(r => {
            setMenu(r.data.menu);
            setGroups([...r.data.groups]);
        });
    }, []);

    return <Layout>
        <Card title={`Раздел настроек контрагента ${contragent?.name}`}>
            <Layout>
                <Layout.Sider width="300">
                    <Menu
                        defaultSelectedKeys={["/contragents"]}
                        selectedKeys={[location.pathname]}
                    >
                        {Object.entries(menu).map(([key, groups]) => {
                            return <Menu.ItemGroup title={map_groups[key]}>
                                {/* Вынужденный хардкод, пока модули не перенесутся */}
                                <Menu.Item key="numerator">
                                    <Link to={`/settings/numerator`}>
                                        Нумераторы
                                    </Link>
                                </Menu.Item>
                                {map_groups[key] === "Модули" &&
                                    Object.entries(integrations).map(([key, value]) => (
                                        <Menu.Item key={`/settings/${value?.codename}`}
                                                   icon={<Tag
                                                       color={value?.enable ? "green" : "red"}><PoweroffOutlined/></Tag>}>
                                            <Link to={`/settings/${value?.codename}`}>
                                                {value?.name}
                                            </Link>
                                        </Menu.Item>
                                    ))
                                }
                                {groups.map(grp => {
                                    return <Menu.Item key={`/settings/${grp}`}>
                                        <Link to={`/settings/${grp}`}>
                                            {map_groups[grp]}
                                        </Link>
                                    </Menu.Item>;
                                })}
                            </Menu.ItemGroup>;
                        })}
                    </Menu>
                </Layout.Sider>
                <Layout.Content style={{padding: "0 24px", minHeight: 280, background: "#fff"}}>
                    <Switch>
                        <Route strict path="/settings/account">
                            <GeneralSettings/>
                        </Route>
                        <Route strict path="/settings/import">
                            <ImportDataComponent/>
                        </Route>
                        <Route strict path="/settings/email">
                            <SMTPSettings/>
                        </Route>
                        <Route strict path="/settings/keycloak">
                            <KeycloakSettings/>
                        </Route>
                        <Route strict path="/settings/buh_1c">
                            <Settings1C/>
                        </Route>
                        <Route strict path="/settings/personal">
                            <LkSettings/>
                        </Route>
                        <Route strict path="/settings/numerator">
                            <NumeratorSettings />
                        </Route>
                        <Route strict path="/settings/s3">
                            <S3Settings/>
                        </Route>
                        <Route strict path="/settings/atolpay.AtolPay">
                            <AtolPayModule/>
                        </Route>
                        <Route strict path={"/settings/edo.Edo"}>
                            <EdoModule/>
                        </Route>
                        <Route strict path="/settings">
                            <Redirect to="/settings/account"/>
                        </Route>
                    </Switch>
                </Layout.Content>
            </Layout>
        </Card>
    </Layout>;
}

function GetImportRecordsAsCsv() {
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const atolImportService = new AtolImportService();

    const handleStartDateTimeChange = (dateTime) => {
        setStartDateTime(dateTime);
    };

    const handleEndDateTimeChange = (dateTime) => {
        setEndDateTime(dateTime);
    };

    const handleDownloadCSV = () => {
        if (!startDateTime || !endDateTime) {
            message.error('Укажите даты');
            return;
        }

        const startDateTimeStr = startDateTime.format('YYYY-MM-DD');
        const endDateTimeStr = endDateTime.format('YYYY-MM-DD');

        atolImportService.get_csv({ start_datetime: startDateTimeStr, end_datetime: endDateTimeStr })
            .catch(error => {
                message.error('Ошибка при загрузке CSV');
            });
    };

    return (
        <div style={{ marginTop: 16 }}>
            <Typography.Text>Экспорт данных импорта в CSV</Typography.Text>
            <div style={{ marginBottom: 16 }}>
                <DatePicker format="YYYY-MM-DD" onChange={handleStartDateTimeChange} />
                &nbsp; - &nbsp;
                <DatePicker format="YYYY-MM-DD" onChange={handleEndDateTimeChange} />
            </div>
            <Button type="primary" onClick={handleDownloadCSV} style={{ marginLeft: 8 }}>
                Скачать CSV
            </Button>
        </div>
    );
}

export function ImportDataComponent(props) {
    const [file, setFile] = useState(null);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [isLoadingOrders, setIsLoadingOrders] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');

    function upload(dataType) {
        if (dataType === 'products') {
            setIsLoadingProducts(true);
        } else if (dataType === 'orders') {
            setIsLoadingOrders(true);
        }

        new SettingService().importData({
            file: file,
            label: dataType,
        }).then(res => {
            message.success('Импорт данных запущен.');
            if (dataType === 'orders') {
                
                if (res.data && res.data.ca_list){
                    const results = Object.entries(res.data.ca_list).map(([key, value]) => `${key}: ${value}`).join('\n');
                    setModalContent(results);
                    setIsModalVisible(true);
                }
            }
        }).catch(error => {
            message.error('Произошла ошибка при импорте данных.');
        }).finally(() => {
            if (dataType === 'products') {
                setIsLoadingProducts(false);
            } else if (dataType === 'orders') {
                setIsLoadingOrders(false);
            }
        });
    }

    return (<>
            <Typography.Title level={4}>Импорт данных</Typography.Title>
            <Spin spinning={isLoadingProducts}>
                <div>
                    <p>
                        <Typography.Text>Импорт Продуктов и Ценовых предложений.</Typography.Text>
                    </p>
                    <Upload onChange={({ file }) => setFile(file.originFileObj)}>
                        <Button icon={<UploadOutlined />}>Выбрать файл</Button>
                    </Upload>
                    <Button onClick={() => upload('products')} disabled={isLoadingProducts}>
                        Загрузить
                    </Button>
                </div>
            </Spin>

            <br />
            <Spin spinning={isLoadingOrders}>
                <div>
                    <p>
                        <Typography.Text>Импорт Заказов и Подписок.</Typography.Text>
                    </p>
                    <Upload onChange={({ file }) => setFile(file.originFileObj)}>
                        <Button icon={<UploadOutlined />}>Выбрать файл</Button>
                    </Upload>
                    <Button onClick={() => upload('orders')} disabled={isLoadingOrders}>
                        Загрузить
                    </Button>
                </div>
            </Spin>

            <GetImportRecordsAsCsv />

            <Modal
                size='large'
                title="Результаты загрузки заказов"
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={800} // Устанавливает ширину модального окна
                style={{ top: 20 }} // Смещает модальное окно вниз
            >
                <pre>{modalContent}</pre>
            </Modal>
    </>);
}

function GeneralSettings({group = "account"}) {

    const [settings, setSettings] = useState([]);
    const [form] = useForm();
    const {system} = useSystemInfo();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            new SettingService().list(),
        ]).then(([set, sig]) => {
            setSettings(set.data.data);
            form.setFieldsValue(set.data.data);
            setLoading(false);
        });
    }, []);

    function onFinish(data) {
        const stt = new SettingService();
        stt.update(data).then(r => {
            notification.success({"message": r.data.msg});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (
        <>
            <Form form={form} layout="vertical" id={"settings"} onFinish={onFinish}>
                {settings.map(s => {
                    if (s.group != group) return null;
                    if (s.type === "bool")
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={s.value}>
                            {renderInput(s.type)}
                        </Form.Item>;
                    else if (s.type === "date")
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={moment(s.value)}>
                            {renderInput(s.type)}
                        </Form.Item>;
                    else if (s.type === "datetime")
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={moment(s.value)}>
                            {renderInput(s.type)}
                        </Form.Item>;
                    else if (s.type === "file")
                        return <Form.Item label={s.descr} name={s.name}>
                            <Input type="file"/>
                        </Form.Item>;
                    else
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={s.value}>
                            {renderInput(s.type)}
                        </Form.Item>;
                })}
            </Form>
            <Form id="logo" layout="vertical">
                <Form.Item label="Логотип" name={"file"}

                           tooltip={`
                        Поддерживается формат (.svg|.png|.jpeg|.gif).
                        Предпочитаемый формат изображений (2:1).\n 
                        Предпочитаемый размер пиксельных изображений должен быть в пределах (250-300px по ширине и 150px-200px по высоте)`}
                >
                    <Upload listType="picture"
                            maxCount={1}
                            defaultFileList={system?.logo ? [{
                                uid: "-1",
                                name: "logo",
                                status: "done",
                                url: system.logo,
                                thumbUrl: system?.logo,
                            }] : []}
                            customRequest={(obj) => {
                                let fd = new FormData();
                                fd.append("file", obj.file);
                                new SettingService().uploadLogo(fd).then(res => {
                                    message.success("лого загружено");
                                    return obj.onSuccess();
                                }).catch(err => {
                                    return obj.onError();
                                });
                            }}
                    >
                        <Button icon={<UploadOutlined/>}>Загрузить</Button>
                    </Upload>
                </Form.Item>
            </Form>
            <Form.Item>
                <Button type={"primary"} htmlType={"submit"} form={"settings"}>
                    Сохранить
                </Button>
            </Form.Item>
        </>
    );
}

function SMTPSettings({group = "email"}) {

    const [settings, setSettings] = useState([]);
    const [emailTestLoading, setEmailTestLoading] = useState(false);
    const [form] = useForm();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            new SettingService().list(),
        ]).then(([set]) => {
            setSettings(set.data.data);
            form.setFieldsValue(set.data.data);
            setLoading(false);
        });
    }, []);

    function onFinish(data) {
        const stt = new SettingService();
        stt.update(data).then(r => {
            notification.success({"message": r.data.msg});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (
        <Form form={form} layout="vertical" id={"email_settings"} onFinish={onFinish}>
            {settings.map(s => {
                if (s.group != group) return null;
                if (["EMAIL_HOST_PASSWORD"].includes(s.name)) {
                    return <Form.Item label={s.descr} name={s.name} initialValue={s.value}>
                        <Input type="password"/>
                    </Form.Item>;
                }
                if (s.type === "bool")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "date")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "datetime")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "file")
                    return <Form.Item label={s.descr} name={s.name}>
                        <Input type="file"/>
                    </Form.Item>;
                else
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
            })}
            <Space>
                <Button type={"primary"} htmlType={"submit"} form={"email_settings"}>
                    Сохранить
                </Button>
                <Button loading={emailTestLoading} disabled={emailTestLoading} onClick={() => {
                    setEmailTestLoading(true);
                    new SettingService().emailTest().finally(() => {
                        setEmailTestLoading(false);
                    });
                }}>
                    Проверить почту
                </Button>
            </Space>
        </Form>
    );
}

function KeycloakSettings({group = "keycloak"}) {

    const [settings, setSettings] = useState([]);
    const [form] = useForm();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            new SettingService().list(),
        ]).then(([set]) => {
            setSettings(set.data.data);
            form.setFieldsValue(set.data.data);
            setLoading(false);
        });
    }, []);

    function onFinish(data) {
        const stt = new SettingService();
        stt.update(data).then(r => {
            notification.success({"message": r.data.msg});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (<Form form={form} layout="vertical" id={"keycloak_form"} onFinish={onFinish}>
            {settings.map(s => {
                if (s.group != group) return null;
                if (["KEYCLOAK_SECRET_KEY"].includes(s.name)) {
                    return <Form.Item label={s.descr} name={s.name} initialValue={s.value}>
                        <Input type="password"/>
                    </Form.Item>;
                }
                if (s.type === "bool")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "date")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "datetime")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "file")
                    return <Form.Item label={s.descr} name={s.name}>
                        <Input type="file"/>
                    </Form.Item>;
                else
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
            })}
            <Form.Item>
                <Button type={"primary"} htmlType={"submit"} form={"keycloak_form"}>
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
}

function LkSettings({group = "personal"}) {

    const [settings, setSettings] = useState([]);
    const [form] = useForm();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            new SettingService().list(),
        ]).then(([set]) => {
            setSettings(set.data.data);
            form.setFieldsValue(set.data.data);
            setLoading(false);
        });
    }, []);

    function onFinish(data) {
        const stt = new SettingService();
        stt.update(data).then(r => {
            notification.success({"message": r.data.msg});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (
        <Form form={form} id={"keycloak_form"} layout="vertical" onFinish={onFinish}>
            {settings.map(s => {
                if (s.group != group) return null;
                if (["KEYCLOAK_SECRET_KEY"].includes(s.name)) {
                    return <Form.Item label={s.descr} name={s.name} initialValue={s.value}>
                        <Input type="password"/>
                    </Form.Item>;
                }
                if (s.type === "bool")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "date")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "datetime")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "file")
                    return <Form.Item label={s.descr} name={s.name}>
                        <Input type="file"/>
                    </Form.Item>;
                else
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
            })}
            <Form.Item>
                <Button type={"primary"} htmlType={"submit"} form={"keycloak_form"}>
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
}

function Settings1C({group = "buh_1c"}) {

    const [settings, setSettings] = useState([]);
    const [form] = useForm();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            new SettingService().list(),
        ]).then(([set]) => {
            setSettings(set.data.data);
            form.setFieldsValue(set.data.data);
            setLoading(false);
        });
    }, []);

    function onFinish(data) {
        const stt = new SettingService();
        stt.update(data).then(r => {
            notification.success({"message": r.data.msg});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (
        <Form form={form} id={"form_1c"} layout="vertical" onFinish={onFinish}>
            {settings.map(s => {
                if (s.group != group) return null;
                if (["PASSWORD_SERVICE_1C"].includes(s.name)) {
                    return <Form.Item label={s.descr} name={s.name} initialValue={s.value}>
                        <Input type="password"/>
                    </Form.Item>;
                }
                if (s.type === "bool")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "date")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "datetime")
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={moment(s.value)}>
                        {renderInput(s.type)}
                    </Form.Item>;
                else if (s.type === "file")
                    return <Form.Item label={s.descr} name={s.name}>
                        <Input type="file"/>
                    </Form.Item>;
                else
                    return <Form.Item label={s.descr} name={s.name}
                                      initialValue={s.value}>
                        {renderInput(s.type)}
                    </Form.Item>;
            })}
            <Form.Item>
                <Button type={"primary"} htmlType={"submit"} form={"form_1c"}>
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
}

function S3Settings({group = "s3"}) {

    const [settings, setSettings] = useState([]);
    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const [changes, setChanges] = useState(false);
    const [testConn, setTestConn] = useState(false);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            new SettingService().list(),
        ]).then(([set]) => {
            setSettings(set.data.data);
            form.setFieldsValue(set.data.data);
            setLoading(false);
        });
    }, []);

    function onFinish(data) {
        const stt = new SettingService();
        stt.update(data).then(r => {
            notification.success({"message": r.data.msg});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (<><Form.Item>
        <Card title="Конфигурация" size="small" extra={
            <Button type="primary" size="small" loading={testConn} disabled={changes} onClick={() => {
                setTestConn(true);
                let extract = {
                    "access_key": form.getFieldValue("AWS_S3_ACCESS_KEY_ID"),
                    "endpoint_url": form.getFieldValue("AWS_S3_ENDPOINT_URL"),
                    "secret_access_key": form.getFieldValue("AWS_S3_SECRET_ACCESS_KEY"),
                    "bucket": form.getFieldValue("AWS_STORAGE_BUCKET_NAME"),
                };
                new SettingService().s3Test(extract).finally(() => {
                    setTestConn(false);
                });
            }}>
                {changes ? "Необходимо сохранить настройки" : "Проверить соединение"}
            </Button>
        }>
            <Form form={form} id={"form_s3"} layout="vertical" onFinish={onFinish}>
                {settings.map(s => {
                    if (s.group != group) return null;
                    if (["AWS_S3_ACCESS_KEY_ID", "AWS_S3_SECRET_ACCESS_KEY"].includes(s.name)) {
                        return <Form.Item label={s.descr} name={s.name} initialValue={s.value}>
                            <Input.Password/>
                        </Form.Item>;
                    }
                    if (["AWS_S3_ENDPOINT_URL"].includes(s.name)) {
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={s.value}>
                            <Input placeholder="https://s3.yourcloud.net"/>
                        </Form.Item>;
                    }
                    if (s.type === "bool")
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={s.value}>
                            {renderInput(s.type)}
                        </Form.Item>;
                    else if (s.type === "date")
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={moment(s.value)}>
                            {renderInput(s.type)}
                        </Form.Item>;
                    else if (s.type === "datetime")
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={moment(s.value)}>
                            {renderInput(s.type)}
                        </Form.Item>;
                    else if (s.type === "file")
                        return <Form.Item label={s.descr} name={s.name}>
                            <Input type="file"/>
                        </Form.Item>;
                    else
                        return <Form.Item label={s.descr} name={s.name}
                                          initialValue={s.value}>
                            {renderInput(s.type)}
                        </Form.Item>;
                })}
            </Form>
        </Card>
    </Form.Item>
        <Form.Item>
            <Card title="Текущая конфигурация" size="small">
                <Descriptions bordered column={1} size="small">
                    {settings.map(el => {
                        if (el.name.includes("CURRENT")) {
                            return <Descriptions.Item
                                label={el.descr}>{JSON.stringify(el.value)}</Descriptions.Item>;
                        }
                    })}
                </Descriptions>
            </Card>
        </Form.Item>
        <Form.Item>
            <Button type={"primary"} htmlType={"submit"} form={"form_s3"}>
                Сохранить
            </Button>
        </Form.Item>
    </>);
}


function AtolPayModule({group = "atolpay"}) {

    const [loading, setLoading] = useState(false);
    const [checkConn, setCheckConn] = useState(false);
    const [form] = useForm();
    const {integrations, setUpdate} = useIntegration();
    // const [genKey, setGenKey] = useState(null);


    function is_available() {
        return integrations["atolpay.AtolPay"]?.enable;
    }

    useEffect(() => {
        setLoading(true);
        new AtolPService().get().then(res => {
            form.setFieldsValue(res.data);
        }).finally(_ => {
            setLoading(false);
        });
    }, []);

    function update(data) {
        new AtolPService().create(data).then(res => {
            message.success("Настройки сохранены");
            setUpdate(p => !p);
        });
    }

    function checkService() {
        setCheckConn(true);
        new AtolPService().check().then(res => {
            message.success("Успешно");
        }).finally(_ => {
            setCheckConn(false);
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (<Form form={form} id={"atolpay"} layout="vertical" onFinish={(d) => update(d)}>
            <Form.Item name="enable" valuePropName="checked" label="Вкл" required={[{required: true}]}>
                <SwitchButton
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />
                {/* <Radio.Group>
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                    </Radio.Group> */}
            </Form.Item>
            <Form.Item name="url" label="Корневой адрес" required={[{required: true}]}>
                <Input disabled={!is_available()} type="url"/>
            </Form.Item>
            <Form.Item name="api_key" label="Секретный ключ авторизации" required={[{required: true}]}>
                <Input.Password disabled={!is_available()}/>
            </Form.Item>
            <Form.Item name="bank_id" label="Идентификатор банка эквайера" required={[{required: true}]}
                       initialValue={100}>
                <Select disabled={!is_available()}>
                    <Select.Option value={100}>Альфа-банк</Select.Option>
                    <Select.Option value={700}>Т-банк</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="tax_system" label="Система налогообложения" required={[{required: true}]}
                       initialValue={100}>
                <Select disabled={!is_available()}>
                    <Select.Option value={0}>Общая СН</Select.Option>
                    <Select.Option value={1}>Упрощенная СН (доходы)</Select.Option>
                    <Select.Option value={2}>Упрощенная СН (доходы минус доходы)</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="generate_check" label="Формировать чек?" required={[{required: true}]}>
                <Radio.Group disabled={checkConn || !is_available()}>
                    <Radio.Button value={true}>Да</Radio.Button>
                    <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Callback принятия платежа в систему">
                <Typography.Text type="secondary"
                                 copyable>{window.location.origin}/api/atolpay/payment</Typography.Text>
            </Form.Item>

            <Space>
                <Button type={"primary"} htmlType={"submit"} form={"atolpay"}>
                    Сохранить
                </Button>
                <Button disabled={checkConn || !is_available()}
                        loading={checkConn} onClick={checkService}>Проверка соединения</Button>
            </Space>
        </Form>
    );
}


function EdoModule({group = "edo"}) {

    const [loading, setLoading] = useState(false);
    const [checkConn, setCheckConn] = useState(false);
    const [form] = useForm();
    const {integrations, setUpdate} = useIntegration();
    const [enabled, setEnabled] = useState(integrations["edo.Edo"]?.enable);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setEnabled(integrations["edo.Edo"]?.enable);
    }, [integrations]);

    useEffect(async () => {
        setLoading(true);
        const es = new EdoService();
        const res = await es.get();

        form.setFieldsValue(res.data);
        setLoading(false);

        const {data: opts} = await es.options();
        setOptions(Object.fromEntries(opts.fields.map(x => [x.name, x])))

    }, []);

    async function update(data) {
        try {
            await new EdoService().create(data);
            notification.success({message: "Настройки сохранены"});
        } catch (err) {
            notification.error({
                message: err.response?.data?.msg,
                description: JSON.stringify(err.response?.data?.details)
            })
        } finally {
            setUpdate(p => !p);
        }
    }

    async function checkService() {
        setCheckConn(true);
        try {
            await new EdoService().check(form.getFieldValue("url"));
            notification.success({message: "Сервис ЭДО доступен"})
        } finally {
            setCheckConn(false);
        }
    }

    async function checkDiadocService() {
        setCheckConn(true);
        try {
            await new EdoService().check_diadoc(form.getFieldValue("url"));
            notification.success({message: "Сервис ДИАДОК доступен"});
        } finally {
            setCheckConn(false);
        }
    }

    function customSetDisabled(val) {
        setEnabled(val);
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (<Form form={form} id={"atolpay"} layout="vertical" onFinish={update}>
            <Form.Item name="enable" valuePropName="checked" label="Вкл" required={true}>
                <SwitchButton onChange={customSetDisabled}
                              checkedChildren={<CheckOutlined/>}
                              unCheckedChildren={<CloseOutlined/>}
                />
            </Form.Item>
            <Form.Item name="url" label={options?.url?.label} required={true}>
                <Input disabled={!enabled} type="url"/>
            </Form.Item>
            <Form.Item name="api_key" label={options?.api_key?.label} required={true}>
                <Input.Password disabled={!enabled}/>
            </Form.Item>
            {/*<Form.Item name="diadoc_url" label="Адрес ДИАДОК (DIADOC API entrypoint)" required={true}>*/}
            {/*    <Input disabled={!enabled} type="url"/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item name="diadoc_client_id" label="ИД клиента ДИАДОК (Client ID DIADOC)" required={false}>*/}
            {/*    <Input.Password disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            <Form.Item name="test_box" label={options?.test_box?.label} required={false}>
                <Input disabled={!enabled}/>
            </Form.Item>
            {/*<Form.Item name="test_box_guid" label="Тестовый GUID ящика  ДИАДОК (для исходящих док)" required={false}>*/}
            {/*    <Input disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            <Form.Item name="test_dest_inn" label={options?.test_dest_inn?.label} required={false}>
                <Input disabled={!enabled}/>
            </Form.Item>
            <Form.Item name="test_dest_kpp" label={options?.test_dest_kpp?.label} required={false}>
                <Input disabled={!enabled}/>
            </Form.Item>
            {/*<Form.Item name="test_diadoc_login" label={options?.test_diadoc_login?.label} required={false}>*/}
            {/*    <Input disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item name="test_diadoc_password" label={options?.test_diadoc_password?.label} required={false}>*/}
            {/*    <Input.Password disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            <Space>
                <Button type={"primary"} htmlType={"submit"} form={"atolpay"}>
                    Сохранить
                </Button>
                <Button disabled={checkConn || !enabled}
                        loading={checkConn} onClick={checkService}>
                    Тест сервиса CAdES
                </Button>
                <Button disabled={checkConn || !enabled}
                        loading={checkConn} onClick={checkDiadocService}>
                    Тест сервиса ДИАДОК
                </Button>
            </Space>
        </Form>
    );
}


function NumeratorSettings(){

    const [numerators, setNumerators] = useState([]);
    const [numModal, setNumModal] = useState({visible: false});
    const [update, setUpdate] = useState(false);
    const {contragent} = useUser();

    useEffect(() => {
        new NumeratorService(contragent.id).list().then(res => {
            setNumerators(res.data.data)
        })
    }, [update])

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Выражение",
            dataIndex: "expression",
            key: "expression",
        },
        {
            title: "Текущее значение",
            dataIndex: "current_index",
            key: "current_index",
        },
        {
            title: "Действия",
            dataIndex: "actions",
            key: "actions",
            render: (val, record) => (
                <Space>
                    <Button size='small' icon={<EditOutlined />} onClick={() => setNumModal({visible: true, item: record})} />
                </Space>
            )
        },
    ]

    return <Table size='small'
                  title={(record) => (
                    <Space>
                        <NumeratorModal
                            item={numModal?.item}
                            visible={numModal?.visible}
                            onCancel={() => {setNumModal({visible: false}); setUpdate(p => !p)}}
                        />
                    </Space>
                  )
                  }
                  pagination={false}
                  columns={columns}
                  dataSource={numerators}
    />
}
