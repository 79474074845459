import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Table} from "antd";

import { ReloadOutlined} from "@ant-design/icons";
import Money from "../../Accounts/utils/Money";
import {SubscribeService} from "../../../API/ServiceAPI";
// import {CrossgradeStatusTag} from "../../Common/Tags";
import {plural_period} from "../../Common/utils";
import {dateTime} from "../../Common/OtherComponents";
import useContragent from "../ContragentContext";

export default function CrossgradeTable({service_item}) {

    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [crossgradeModal, setCrossGradeModal] = useState({visible: false, item: null});
    const [crossgradeList, setCrossgradeList] = useState([]);
    const {contragent} = useContragent();


    const columns = [
        {title: '№', dataIndex: 'id', key: 'id'},
        {
            title: 'период', key: 'activate', render: (_, record) => {
                return `с ${dateTime(record?.date_start, true, false,'не начат')} - 
            ${dateTime(record?.date_end, true, false,'не указан')}`
            }
        },
        {
            title: 'наименование', dataIndex: 'name', key: 'name', render: (_, record) => {
                return record?.offer?.name
            }
        },
        {
            title: 'период', dataIndex: 'period', key: 'period', render: (_, record) => {
                return plural_period(record?.offer?.period)
            }
        },
        {
            title: 'количество', dataIndex: 'count', key: 'count', render: (val, record) => {
                return <>
                    <Money sum={record?.offer?.price} currency={record?.offer?.currency}/>
                    {` x${val}`}
                </>
            }
        },
        // { title: 'кол-во', dataIndex: 'num', key: 'num' },
        {
            title: 'сумма', dataIndex: 'price', align: 'right', key: 'price', width: 150, render: (_, record) => (
                <>
                    <Money sum={record?.offer?.price * record?.count} currency={record?.offer?.currency}/>
                </>
            )
        },
        {
            title: 'НДС', dataIndex: 'tax', align: 'right', key: 'tax', width: 150, render: (_, record) => (
                <Money.NDS nds={record?.offer?.taxes}/>
            )
        },
        // {title: 'статус', dataIndex: 'status', align: 'right', key: 'status', render: (el) => {console.log(el); return CrossgradeStatusTag(el)}},
    ];


    useEffect(() => {
        if (service_item) {
            setLoading(true);
            new SubscribeService(contragent?.id, service_item?.id).getCrossgrades().then(res => {
                setCrossgradeList(res.data.data);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [service_item, update])

    return (
        <Table
            rowKey='id'
            loading={loading}
            size='small'
            columns={columns}
            dataSource={crossgradeList}
            title={() => (
                <Row justify='space-between' gutter={16}>
                    <Col flex='auto'>
                        <Input.Search type='primary'/>
                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined/>} onClick={() => setUpdate(p => !p)}/>
                    </Col>
                </Row>
            )}
        />
    )
}
