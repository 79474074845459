import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Table} from "antd";

import {ReloadOutlined} from "@ant-design/icons";
import {PackageService} from 'src/API/PackageAPI';
import {OfferService} from 'src/API/OfferAPI';
import Money from "../../Accounts/utils/Money";
import useUser from "../../../Providers/UserProvider";
import useContragent from "../ContragentContext";
// import {plural} from '../../Common/utils';

// Доделать таблицу после мерж фильтро Гоши
export default function OfferTable({onSelectOffer, type, filter={}}){
    // таблица с предложениями от заданного контрагента

    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [offerList, setOfferList] = useState([]);
    const [params, setParams] = useState({type, page: 1, limit: 10});
    const [total, setTotal] = useState(0);
    const {contragent} = useContragent();

    useEffect(() => {
        setLoading(true)
        new OfferService(contragent?.id).list({params: {...params, ...filter}}).then(res => {
            setOfferList(res.data.data);
            setTotal(res.data.count);
        }).finally(() => {
            setLoading(false);
        })
    }, [update, params])


    const columns = [
        // { key: 'actions', width: 10, render: (record) => {}},
        { title: '№', dataIndex: 'id', key: 'id' },
        { title: 'наименование', dataIndex: 'name', key: 'name' },
        { title: 'период', dataIndex: 'period', key: 'period', render: (val => {
            // вынести в функцию в lib
            return val ? `${val?.days}д ${val?.months}м ${val?.years}г` : null
        }) },
        // { title: 'кол-во', dataIndex: 'num', key: 'num' },
        { title: 'сумма', dataIndex: 'price', align: 'right', key: 'price', width: 150, render: (sum) => (
            <Money sum={sum} />
        )},
        { key: 'actions', width: 100, render: (record) => (
            <Button size='small' type='primary' onClick={() => onSelectOffer(record)}>Выбрать</Button>
        )},
        // { title: 'НДС', dataIndex: 'tax', align: 'right', key: 'tax', width: 150, render: (sum) => (
        //     <Money sum={sum} />
        // )},
      ];

    return <>
        <Table
            rowKey='id'
            loading={loading}
            size='small'
            columns={columns}
            dataSource={offerList}
            pagination={{ pageSize: params.limit, total: total}}
            onChange={(e) => setParams({page: e.current, limit: e.pageSize})}
            title={(record) => (
                <Row justify='space-between' gutter={16}>
                    <Col flex='auto'>
                        <Input.Search />
                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined/>} onClick={() => setUpdate(p => !p)} />
                    </Col>
              </Row>
            )}
        />
    </>
}

export function PackageTable({onSelect, type}){
    // таблица с предложениями от заданного контрагента

    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [selectedRows, setSelectedRows] = useState([]);
    const [offerList, setOfferList] = useState([]);
    const {contragent} = useContragent();

    useEffect(() => {
        setLoading(true)
        new PackageService(contragent?.id).list({params: {type}}).then(res => {
            setOfferList(res.data.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [update])


    const columns = [
        // { key: 'actions', width: 10, render: (record) => {}},
        { title: '№', dataIndex: 'id', key: 'id' },
        { title: 'наименование', dataIndex: 'name', key: 'name' },
        // { title: 'сумма', dataIndex: 'price', align: 'right', key: 'price', width: 150, render: (sum) => (
        //     <Money sum={sum} />
        // )},
        { key: 'actions', width: 100, render: (record) => (
            <Button size='small' type='primary' onClick={() => onSelect(record)}>Выбрать</Button>
        )},
      ];

    return <>
        <Table
            rowKey='id'
            loading={loading}
            size='small'
            columns={columns}
            dataSource={offerList}
            title={(record) => (
                <Row justify='space-between' gutter={16}>
                    <Col flex='auto'>
                        <Input.Search />
                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined/>} onClick={() => setUpdate(p => !p)} />
                    </Col>
              </Row>
            )}
        />
    </>
}
