import BaseService, {error_handler} from "./BaseAPI";

class AtolPayService extends BaseService {
    MODEL = 'atolpay'

    register(data) {
        // Регистрация платежа
        return this._post('register', data).then(res => {
            return Promise.resolve(res)
        }).catch(error_handler)
    }
}

export {
    AtolPayService,
}
