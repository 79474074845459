import {Col, Menu, notification, Popconfirm, Row, Tag, Typography} from "antd";
import {SubscribeService, SubscribeServiceGroup} from "app/API/ServiceAPI";
import FlexibleTable from "app/Base/Tables/FlexibleTable/FlexibleTable";
import Money from "app/Components/Accounts/utils/Money";
import {OrdersSubscribeModal} from "app/Components/Contragents/Modals/OrderModal";
import {useSubscribeData} from "app/Data/Base";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import RevenueService from "src/API/RevenueAPI";
import Modal from "src/Base/Modals/Modal";
import useIntegration from "src/Providers/IntegrationsProvider";
import {BlockingModal} from "../../Common/Modal/BlockingModal";
import {DateTime} from "../../Common/OtherComponents";
import {CrossgradeStatusTag} from "../../Common/Tags";
import {OrderDetail} from "../Cards/OrderCards";

import useContragent from "../ContragentContext";
import ATOLRefillCounterModal from "../Modals/ATOL/RefillMeterModal";
import {SubscribePlanModal} from "../Modals/BillingModal";

import CounterModal from "../Modals/CounterModal";
import SubscribeHistoryModal from "../Modals/CrossgradeModal";
import { CancelServiceModal } from "../Modals/BillingModal";
import SubscribeCrossgradeModal, {
    SubscribeGroupModal,
    SubscribeProlongGroupModal,
    SubscribeAttributesModal,
} from "../Modals/CrossgradeServiceChange";


export default function ServiceTable() {
    // Таблица с услугами

    const [counterModalVisible, setCounterModalVisible] = useState(false);
    const [billingModalVisible, setBillingModalVisible] = useState(false);
    const [atolRefillModal, setAtolRefillModal] = useState({visible: false});
    const {atol} = useIntegration();
    const [crossgradeModal, setCrossgradeModal] = useState({
        visible: false, item: null, isAtolSpecial: false
    });
    const [subGroupModal, setSubGroupModal] = useState({
        visible: false, item: null, isAtolSpecial: false
    });
    const [prolongationGroupModal, setProlongationGroupModal] = useState({
        visible: false, item: null
    });
    const [historySubscribeModal, setHistorySubscribeModal] = useState({
        visible: false, item: null
    });
    const [serviceItem, setServiceItem] = useState(null);
    const [isOrderDetails, setOrderDetails] = useState(false);
    const [order, setOrder] = useState({});
    const [attributesModal, setAttributesModal] = useState({
        visible: false, item: null
    });
    const [cancelServiceModal, setCancelServiceModal] = useState({visible: false});

    const {contragent} = useContragent();
    const [isModalBlock, setModalBlock] = useState(false);
    const [isModalOrders, setIsModalOrders] = useState(false);

    const model = useSubscribeData({contragent});
    const {toggleTableUpdate} = model;

    function createRevenueFullPeriod(record) {
        new RevenueService().create_full_period(record.id).then(r => {
            notification.success({
                                     message: "Реализация создана",
                                     description: "Реализация создана на весь период услуги",
                                 });
        }).catch(RevenueService.warning_handler);
    }

    const menu_actions = (record) => {

        if (record.children) {
            let is_not_canceled = record.termination_date === null && record.status !== 5

            return <Menu>
                {!atol && <>
                    <Menu.Item disabled={!is_not_canceled} onClick={() => {
                        setSubGroupModal({visible: true, items: record.children, isAtolSpecial: false});
                        setServiceItem(record);
                    }}>
                        Редактировать
                    </Menu.Item>
                    <Menu.Item disabled={!is_not_canceled} onClick={() => setProlongationGroupModal({visible: true, item: record})}>
                        Изменить дату продления
                    </Menu.Item></>}
                {atol && record.status !== 5 && <>
                    <Menu.Item onClick={() => {
                        setSubGroupModal({visible: true, items: record.children, isAtolSpecial: false});
                        setServiceItem(record);
                    }}>
                        Редактировать
                    </Menu.Item>
                    <Menu.Item onClick={() => {
                        setSubGroupModal({visible: true, items: record.children, isAtolSpecial: true});
                        setServiceItem(record);
                    }}>
                        АТОЛ: изменение подписки/пакета после корректировки реализации
                    </Menu.Item>
                </>
                }

                {record.status !== 5 &&
                    <Menu.Item disabled={!is_not_canceled} onClick={() => {
                        // setSubGroupModal({visible: true, items: record.children});
                        new SubscribeServiceGroup(contragent?.id, record?.id)
                            .manual_renewal()
                            .then(res => {
                                notification.success({
                                                    message: "Создан заказ на продление.",
                                                    description: "Создан заказ на продление.",
                                                });
                        }).catch(err => {
                            SubscribeService.error_handler(err.response);
                        })
                            setServiceItem(record);
                        }}>
                        Создать заказ на продление
                    </Menu.Item>
                }

                {/* {record.status !== 5 &&
                    <Menu.Item disabled={!is_not_canceled} onClick={() => {
                        new SubscribeServiceGroup(record?.id)
                            .manual_renewal({prolong_type: 'from_now'})
                            .then(res => {
                                notification.success({
                                                    message: "Создан заказ на продление.",
                                                    description: "Создан заказ на продление.",
                                                });
                        }).catch(err => {
                            SubscribeService.error_handler(err.response);
                        })
                        setServiceItem(record);
                    }}>
                        Продлить с текущей даты
                    </Menu.Item>
                } */}
                {record.status !== 5 &&
                    <Menu.Item danger disabled={!is_not_canceled}>
                            <Popconfirm
                                title={<>
                                    При отмене услуги: <br />
                                    1. Статус услуги изменится на "Отменена".<br />
                                    2. За отработанный период будет создана реализация.<br />
                                    3. Средства за неотработанный период будут возвращены на баланс.<br />
                                    Вы действительно хотите отменить эту подписку?
                                </>}
                                onConfirm={() => {
                                    new SubscribeServiceGroup(contragent?.id, record.group).cancel().then(res => {
                                        notification.success({
                                            message: 'Группа подписок прекращена',
                                            description: "Группа подписок прекращена. Неиспользованные средства возвращены на баланс.",
                                        })})
                                }}
                                okText="Да"
                                cancelText="Нет"
                            >
                                Отменить
                            </Popconfirm>
                        </Menu.Item>
                }

            </Menu>
        }

        return <Menu>
            {!record?.package_id && record.status !== 5 &&
                <Menu.Item onClick={() => {
                    setCrossgradeModal({item: record, visible: true, isAtolSpecial: false});
                    setServiceItem(record);
                }}>Редактировать</Menu.Item>}
            {!record?.package_id && record.status !== 5 &&
                <Menu.Item onClick={() => {
                    setServiceItem(record);
                    new SubscribeService(contragent?.id, record?.id)
                        .manual_renewal()
                        .then(res => {
                            notification.success({
                                                 message: "Создан заказ на продление.",
                                                 description: "Создан заказ на продление.",
                                             });
                    }).catch(err => {
                        SubscribeService.error_handler(err.response);
                    })
                }}>Создать заказ на продление</Menu.Item>}

            {/* {!record?.package_id && record.status !== 5 &&
                <Menu.Item onClick={() => {
                    setServiceItem(record);
                    new SubscribeService(record?.id)
                        .manual_renewal({prolong_type: 'from_now'})
                        .then(res => {
                            notification.success({
                                                 message: "Создан заказ на продление.",
                                                 description: "Создан заказ на продление.",
                                             });
                    }).catch(err => {
                        SubscribeService.error_handler(err.response);
                    })
                }}>Продлить с текущей даты</Menu.Item>} */}
            {/* это действие для АТОЛ. При отключенном модуле не должно отображаться */}
            {!record?.package_id && record.type === 3 && atol && record.status !== 5 &&
                <Menu.Item onClick={() => {
                    setCrossgradeModal({item: record, visible: true, isAtolSpecial: true});
                    setServiceItem(record);
                }}>АТОЛ: изменение подписки/пакета после корректировки реализации</Menu.Item>}
            {record?.type === 2 &&
                <>
                    {record.termination_date === null &&
                        <Menu.Item onClick={() => {
                            setCounterModalVisible(true);
                            setServiceItem(record);
                        }}>Добавить показание</Menu.Item>}
                    {/* {record.termination_date === null &&
                        <Menu.Item onClick={() => {
                            new CounterService(record?.id).activateCounter().then(res => {
                                message.success('показания расчитаны. Создан заказ');
                                // toggleTableUpdate();
                            }).catch(err => {
                                CounterService.error_handler(err.response)
                            })
                        }}>расчитать показания</Menu.Item>
                    } */}
                    {atol &&
                        <Menu.Item onClick={() => {
                            setAtolRefillModal({visible: true, item: record});
                        }}>
                            АТОЛ: Создать заказ на пополнение счетчика
                        </Menu.Item>}
                </>}

            {/* {record?.package_id && */}
            <Menu.Item onClick={() => {
                setAttributesModal({item: record, visible: true});
                setServiceItem(record);
            }}>Редактировать атрибуты</Menu.Item>

            <Menu.Item onClick={() => {
                setBillingModalVisible(true);
                setServiceItem(record);
            }}>План-Факт</Menu.Item>
            {/* <Menu.Item onClick={() => {
                setSubDetalizationModal(true);
                setServiceItem(record);
            }}>Факт</Menu.Item> */}
            <Menu.Item onClick={() => {
                setServiceItem(record);
                setIsModalOrders(true);
            }}>
                История заказов
            </Menu.Item>
            <Menu.Item onClick={() => setHistorySubscribeModal(p => {
                return {item: record, visible: true};
            })}>
                История параметров
            </Menu.Item>

            {record.termination_date === null && record.status !== 5 &&
                <Menu.Item onClick={() => createRevenueFullPeriod(record)}>
                    Создать реализацию за весь период
                </Menu.Item>}
            {record.termination_date === null && record.status !== 5 && !record.package_id &&
                <Menu.Item key="block" danger onClick={() => {
                    setModalBlock(true);
                    setServiceItem(record);
                }}>
                    Заблокировать
                </Menu.Item>}
            {record.termination_date === null && record.status !== 5 && !record.package_id &&
                <Menu.Item danger key='cancel_subscribe' onClick={() => setCancelServiceModal({visible: true, item: record})}>
                        {/* <Popconfirm
                            title={<>
                            При отмене услуги: <br />
                            1. Статус услуги изменится на "Отменена".<br />
                            2. За отработанный период будет создана реализация.<br />
                            3. Средства за неотработанный период будут возвращены на баланс.<br />
                            Вы действительно хотите отменить эту подписку?
                        </>}
                            onConfirm={() => {
                                    new SubscribeService(contragent?.id, record?.id).closeService().then(res => {
                                        notification.success({
                                                                 message: "Подписка прекращена",
                                                                 description: "Подписка прекращена. Неиспользованные средства возвращены на баланс.",
                                                             });
                                        // toggleTableUpdate();
                                    }).catch(err => {
                                        SubscribeService.error_handler(err.response);
                                    })
                                }}
                            okText="Да"
                            cancelText="Нет"
                        >
                        </Popconfirm> */}
                        Отменить
                </Menu.Item>
            }
        </Menu>

    };

    const columns = [
        {title: "№", dataIndex: "id", key: "id"}, {
            title: "Тип",
            dataIndex: "type_name",
            key: "type_name",
        }, {
            title: "Наименование услуги", dataIndex: "offer_name", key: "offer_name",
        }, {
            title: "Цена", dataIndex: "price", key: "price",
            align: "right",
            render: (val, rec) => <Money sum={val}/>,
        }, {
            title: "НДС", dataIndex: "tax", key: "tax",
            align: "right",
            render: (val, rec) => <Money sum={val}/>,
        }, {
            title: "Контрагент",
            dataIndex: "buyer",
            key: "buyer",
            render: (ctr, record) => <Link to={`/contragents/${record?.contract?.buyer?.id}`}>
                {record?.contract?.buyer?.name}
            </Link>,
        }, {
            title: "Дата создания",
            dataIndex: "_created",
            key: "_created",
            render: (value) => {
                return <DateTime dt={value} dateOnly/>;
            },
        }, {
            title: "Дата продления", key: "settling_day", dataIndex: "settling_day", // width: 150,
            render: (value, record) => <DateTime dt={value} dateOnly/>,
        }, {
            title: "Статус", width: 150, dataIndex: "status", key: "status", align: "right", render: (_, record) => {
                if (record.active_blockings) return <Tag color="red">Заблокировано</Tag>;
                return CrossgradeStatusTag(record.status);
            },
        },
    ];

    return <FlexibleTable model={model}
                          columns={columns}
                          actions={menu_actions}
                          // rowClassName={(record) => {
                          //     if (record.package_id) {
                          //         return styles.blue;
                          //     }
                          //     return null;
                          //     // return "";
                          // }}
                          // onRow={(rec) => {
                          //     if (rec.group && !rec.children?.length)
                          //         // return {className: styles.tableRowBlock};
                          //         return {style: {border: "solid 2px "
                          // }}
                          title={() => (<Row justify="space-between">
                              <Col>
                                  <CounterModal
                                      visible={counterModalVisible}
                                      service_item={counterModalVisible ? serviceItem : null}
                                      onCancel={() => setCounterModalVisible(false)}
                                  />
                                  <SubscribePlanModal
                                      visible={billingModalVisible}
                                      service_item={billingModalVisible ? serviceItem : null}
                                      onCancel={() => setBillingModalVisible(false)}
                                  />
                                  <SubscribeHistoryModal
                                      visible={historySubscribeModal?.visible}
                                      service_item={historySubscribeModal?.item}
                                      onCancel={() => setHistorySubscribeModal(p => {
                                          return {...p, visible: false};
                                      })}
                                  />
                                  <SubscribeProlongGroupModal
                                      visible={prolongationGroupModal?.visible}
                                      service={prolongationGroupModal?.item}
                                      onFinish={() => {
                                          setProlongationGroupModal({visible: false});
                                          toggleTableUpdate();
                                      }}
                                      onCancel={() => {
                                          setProlongationGroupModal({visible: false});
                                          toggleTableUpdate();
                                      }}
                                  />
                                  <SubscribeAttributesModal
                                    visible={attributesModal?.visible}
                                    service_item={attributesModal?.item}
                                    onFinish={() => {
                                        setAttributesModal({visible: false});
//                                        setUpdate(p => !p);
                                    }}
                                    onCancel={() => {
                                        setAttributesModal({visible: false});
//                                        setUpdate(p => !p);
                                    }}
                                />
                                  <Modal visible={isOrderDetails}
                                         btnname={"Закрыть"}
                                         title={"Детали первичного заказа"}
                                         onSuccess={() => setOrderDetails(false)}
                                         onCancel={() => setOrderDetails(false)}
                                         hideCancelBtn
                                         width={"90%"}>
                                      {isOrderDetails && <OrderDetail orderDetail={order}
                                                                      contragent={contragent}
                                                                      setOrderDetail={setOrder}
                                          // setIsDetailView={setOrderDetails}
                                      />}
                                  </Modal>
                                  <BlockingModal
                                      targetObject={serviceItem}
                                      onCancel={() => setModalBlock(false)}
                                      onSuccess={() => setModalBlock(false)}
                                      visible={isModalBlock}
                                  />
                                  <SubscribeCrossgradeModal
                                      visible={crossgradeModal?.visible}
                                      service_item={serviceItem}
                                      item={crossgradeModal?.item}
                                      isAtolSpecial={crossgradeModal.isAtolSpecial}
                                      onSuccess={() => {
                                          setCrossgradeModal({visible: false});
                                          toggleTableUpdate();
                                      }}
                                      onCancel={() => {
                                          setCrossgradeModal(p => {
                                              return {...p, visible: false};
                                          });
                                          toggleTableUpdate();
                                      }}
                                  />
                                  <SubscribeGroupModal
                                      visible={subGroupModal?.visible}
                                      service_item={serviceItem}
                                      item={subGroupModal?.item}
                                      isAtolSpecial={subGroupModal.isAtolSpecial}
                                      onSuccess={() => {
                                          setSubGroupModal({visible: false});
                                          toggleTableUpdate();
                                      }}
                                      onCancel={() => {
                                          setSubGroupModal(p => {
                                              return {...p, visible: false};
                                          });
                                          toggleTableUpdate();
                                      }}
                                  />
                                  <ATOLRefillCounterModal
                                      visible={atolRefillModal?.visible}
                                      item={atolRefillModal?.item}
                                      onCancel={() => {
                                          setAtolRefillModal({visible: false});
                                          toggleTableUpdate();
                                      }}
                                  />
                                  <OrdersSubscribeModal
                                      subscribe={serviceItem}
                                      onCancel={() => setIsModalOrders(false)}
                                      visible={isModalOrders}/>
                                  <CancelServiceModal
                                      visible={cancelServiceModal?.visible}
                                      item={cancelServiceModal?.item}
                                      onCancel={() => setCancelServiceModal({visible: false})}
                                  />
                              </Col>
                          </Row>)}/>;
}
