import {MoreOutlined, ReloadOutlined} from "@ant-design/icons";
import {
    Button,
    Col,
    Descriptions,
    Dropdown,
    Menu,
    notification,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import {EdoService} from "app/API/EdoAPI";
import {CMenuItem} from "app/Base/Components/Menu";
import useContragent from "app/Components/Contragents/ContragentContext";
import useIntegration from "app/Providers/IntegrationsProvider";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useToggle} from "react-use";
import DocumentService, {DebtInvoiceService} from "src/API/DocumentAPI";
import Money from "../../Accounts/utils/Money";
import {DateTime} from "../../Common/OtherComponents";
import {OrderPayStatusTag} from "../../Common/Tags";
import DocModal from "../Modals/DocModal";
import DocumentCreateModal, {DocumentModal} from "../Modals/DocumentCreateModal";
import {PaymentModal} from "../Modals/PaymentModal";
import RefillInvoiceModal from "../Modals/RefillInvoiceModal";


export default function DocumentTable({doctype, ...props}) {
    const [data, setData] = useState([]);
    const [docVisible, setDocVisible] = useState(false);
    const [paymentModal, setPaymentModal] = useState({visible: false});
    const [documentModal, setDocumentModal] = useState({visible: false});
    const [isModal, setIsModal] = useState(false);
    const [doc, setDoc] = useState({id: null, type: null, href: null});
    const [update, toggle] = useToggle(false);
    const [loading, setLoading] = useState(false);
    const {contragent} = useContragent();
    const {isEnabled} = useIntegration();
    const [statuses, setStatuses] = useState({});
    const [status_ref, setStatus_ref] = useState({});

    const params = useParams();

    function get_status(id) {
        if (statuses) {
            const st_obj = statuses[id];
            const status = status_ref[st_obj?.status];

            return (status) ? <Tooltip title={st_obj?.msg || status}>
                    <Tag>
                        {status}
                    </Tag>
                </Tooltip> : null;
        } else return null;
    }

    function get_edo_status(id) {
        if (statuses) return statuses[id]?.edo_status_descr;
    }

    function get_date(id) {
        if (statuses)
            return <DateTime dt={statuses[id]?.dte}/>;
    }

    let columns = [
        {
            title: "",
            key: "operation",
            width: 10,
            render: (record) => {
                return <Dropdown overlay={( // нужно каким-то образом выделить меню в отдельный компонент и собирать его исходя из интеграций
                    <Menu>
                        <Menu.Item key='1' onClick={() => setDocumentModal({visible: true, item: record})}>
                            Редактировать
                        </Menu.Item>
                        <Menu.Item key="payment" onClick={() => setPaymentModal({visible: true, item: record})}>
                            Перейти к оплате
                        </Menu.Item>
                        <Menu.Item key="1">
                            <Typography
                                onClick={() => {
                                    setDoc({id: record.id, type: record.type, href: record.document_url});
                                    setDocVisible(true);
                                }}
                            >Показать документ</Typography>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Typography
                                onClick={() => new DocumentService(contragent?.id).downloadDocument(record.document_url)}>
                                Загрузить PDF</Typography>
                        </Menu.Item>
                        <CMenuItem integration={"edo.Edo"} key={"edo"}>
                            <Typography onClick={async () => {
                                const es = new EdoService(record.id);
                                try {
                                    const r = await es.sign_and_send();
                                    if (r.data.status == "warning")
                                        notification.warning({
                                            message: r.data.msg,
                                            description: r.data?.detail || r.data?.details,
                                        });
                                    else
                                        notification.success({
                                            message: "ЭДО",
                                            description: "Документ отправлен в ЭДО",
                                        });
                                    await es.get_status();
                                    toggle();
                                } catch (err) {
                                    if (err.response.data.status == "warning")
                                        notification.warn({
                                            message: err.response.data.msg,
                                            description: err.response?.data?.details,
                                        });
                                    else
                                        notification.error({
                                            message: err.response.data.msg,
                                            description: err.response.data.details,
                                        });
                                }
                            }}>
                                Подписать и отправить в ЭДО
                            </Typography>
                        </CMenuItem>
                        <CMenuItem integration={"edo.Edo"} key={"edo_status"}>
                            <Typography onClick={async () => {
                                try {
                                    await new EdoService(record.id).get_status();
                                    notification.success({message: "Статус обновлён!"});
                                    toggle();
                                } catch (e) {
                                    const ee = e.response?.data;
                                    notification.error({
                                        message: "Ошибка!",
                                        description: ee?.details
                                            || ee?.detail
                                            || ee?.msg
                                            || ee?.message
                                            || "Невозможно выполнить запрос статуса. " +
                                            "Сервис недоступен, либо работает с ошибками",
                                    });
                                }
                            }}>
                                Обновить статусы
                            </Typography>
                        </CMenuItem>
                    </Menu>
                )} trigger={["click"]} placement="bottomRight">
                    <Button type="text" icon={<MoreOutlined/>}/>
                </Dropdown>;
            },
        },
        {title: "№", dataIndex: "id", width: 50},
        {title: 'Номер', dataIndex: 'number', key: 'number',render: (text, record) => {
            return <Typography.Link onClick={() => new DocumentService(contragent?.id).downloadDocument(record.document_url)}>
                {record.number}
            </Typography.Link>
        }},
        // {
        //     title: "Наименование",
        //     dataIndex: "document_url",
        //     render: (text, record) => {
        //         return <Typography.Link
        //             onClick={() => new DocumentService(contragent?.id).downloadDocument(record.document_url)}>
        //             {record.name || record.number}
        //         </Typography.Link>;
        //     },
        // },
        {title: "Тип", dataIndex: "type"},
        {
            title: "Сумма", dataIndex: "pure_amount", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },
        {
            title: "Контрагент", dataIndex: "buyer_name", render: (val, rec) => (
                <Link to={`/contragents/${rec.buyer_id}`}>{val}</Link>
            ),
        },
        {
            title: "Сумма с НДС", dataIndex: "amount", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },
        {
            title: "Дата", dataIndex: "_created", render: dt => (
                <DateTime dt={dt}/>
            ),
        },
    ];


    if (isEnabled("edo.Edo")) {
        columns = [
            ...columns,
            {title: "Статус КриптоПРО", dataIndex: "status", render: (_, x) => get_status(x.id)},
            {title: "Статус ЭДО", dataIndex: "status_edo", render: (_, x) => get_edo_status(x.id)},
            {title: "Дата отправки ЭДО", dataIndex: "date_edo", render: (_, x) => get_date(x.id)},
        ];
    }

    useEffect(() => {
        if (isEnabled("edo.Edo")) {
            if (data?.length) {
                const ids = data.map(x => x.id);
                new EdoService().get_statuses(ids).then(res => {
                        setStatuses(res.data.data);
                        setStatus_ref(Object.fromEntries(res.data.status_ref));
                    },
                );
            }
        }
    }, [data]);
    useEffect(() => {
        setLoading(true);
        new DocumentService(contragent?.id).list({params: {contragent: params.id}}).then((r) => {
            setData(r.data.data);
            setLoading(false);
        });
    }, [params.id, update]);


    function handleDebtInvoice() {
        new DebtInvoiceService(contragent.id).create({contragent: contragent.id}).then((r) => {
                notification.success({message: `Счет на задолженность сформирован.`},
                );
            },
        );
    }

    return <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        size="small"
        loading={loading}
        expandable={{
            expandedRowRender: record => {
                return record.payments?.map(el => (
                    <Descriptions size="small" bordered column={1}>
                        <Descriptions.Item label="Статус">{OrderPayStatusTag(el.status)}</Descriptions.Item>
                        <Descriptions.Item label="Метод оплаты">{el.payment_method}</Descriptions.Item>
                        <Descriptions.Item label="Ссылка на оплату">
                            <a href={el.payment_url}>{el.payment_url}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Описание">
                            {el.description}
                        </Descriptions.Item>
                    </Descriptions>
                ));
            }
            ,
            rowExpandable: record => record.payments !== null && record.payments?.length,
        }}
        title={() => (
            <Row justify="space-between">
                <Col>
                    <Space>
                        <Button onClick={() => setIsModal(true)}>Счёт на пополнение</Button>
                        <Button onClick={handleDebtInvoice}>Счёт на задолженность</Button>
                        {/* TODO: ЗАПИЛИТЬ СОЗДАНИЕ ДОКУМЕНТОВ!!! */}
                        {/* <Button>Создать документ</Button> */}
                    </Space>
                    <RefillInvoiceModal
                        onCancel={() => {
                            setIsModal(false);
                            toggle();
                        }}
                        setIsModal={setIsModal}
                        visible={isModal}/>
                </Col>
                <Col>
                    <Button icon={<ReloadOutlined/>} onClick={() => toggle()}/>
                    <DocumentCreateModal type={doctype} visible={doctype != null}/>
                    <DocModal
                        link={doc.href}
                        visible={docVisible}
                        onCancel={() => setDocVisible(prev => !prev)}
                        doc_id={doc.id}
                        title={doc.type}
                        height={"720px"}
                        width={"1150px"}
                    />
                    <DocumentModal
                        visible={documentModal?.visible}
                        item={documentModal?.item}
                        onCancel={() => setDocumentModal({visible: false})}
                    />
                    <PaymentModal
                        visible={paymentModal?.visible}
                        item={paymentModal?.item}
                        onCancel={() => setPaymentModal({visible: false})}/>
                </Col>
            </Row>
        )}
        {...props}/>;
}
