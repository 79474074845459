import React, {useState} from "react";

import {Menu, notification, Tag, Typography} from "antd";

import {BlockingService} from "app/API/BlockingAPI";

import {BlockingModal} from 'src/Components/Common/Modal/BlockingModal';
import useContragent from "../ContragentContext";
import FlexibleTable from "app/Base/Tables/FlexibleTable/FlexibleTable";
import {useBlockingData} from "src/Data/Base";
import moment from 'moment';

const dtFormat = 'DD.MM.YYYY HH:mm:ss'

/// Костылик, потом исправить (скопировано из DateTime)
function dateTime(dt, date_only = false, time_only = false, ifnull = " - ") {
    if (!dt)
        return ifnull;

    // const offst = (new Date()).getTimezoneOffset() * (-1);
    try {
        // const mdt = moment(dt).utcOffset(offst);
        const mdt = moment(dt);

        let fdt = mdt.format(dtFormat);
        if (date_only){
            fdt = mdt.utc(false).format('DD.MM.YYYY');
        }

        if (time_only)
            fdt = mdt.format('HH:mm:ss');

        return fdt;
    } catch (e) {
        return ifnull;
    }
}

function DateTime({dt, dateOnly = false, timeOnly = false, style = {}, ifnull = " - "}) {
    return <Typography.Text style={style}>{dateTime(dt, dateOnly, timeOnly, ifnull)}</Typography.Text>
}


export function BlockingTable() {
    const [modalShow, setModalShow] = useState(false);
    const [blocking, setBlocking] = useState();
    const {contragent} = useContragent();
    const model = useBlockingData(contragent);
    const {toggleTableUpdate} = model;

    function cancelBlocking(blocking_id) {
        new BlockingService(contragent?.id, blocking_id).cancel().then(res => {
            notification.success({message: `Блокировка отменена`});
        });
    }

    const columns = [
        {
            title: "ID", dataIndex: "id", key: "id"
        },
        {
            title: "Тип", dataIndex: "btype", key: "btype",
        },
        {
            title: "Объект блокировки",
            dataIndex: "target_object_name",
            key: "target_object_name",
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (val, obj) => {
                if (val == 'active')
                    return <Tag color='green'>Активна</Tag>
                if (val == 'inactive')
                    return <Tag color='gray'>Неактивна</Tag>
                if (val == 'postponed')
                    return <Tag color='blue'>Отложена</Tag>
            }
        },
        {
            title: "Родительская блокировка",
            dataIndex: "parent_blocking",
            key: "parent_blocking",
        },
        {
            title: "Связанные КА",
            dataIndex: "contragents",
            key: "contragents",
        },
        {
            title: "Комментарий",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Дата активации",
            dataIndex: "activate_date",
            key: "activate_date",
            render: (value) => <DateTime dt={value} dateOnly />
        },
        {
            title: "Дата создания",
            dataIndex: "_created",
            key: "_created",
            render: (value) => <DateTime dt={value}/>
        },
        {
            title: "Дата окончания",
            dataIndex: "cancel_date",
            key: "cancel_date",
            render: (value) => <DateTime dt={value} dateOnly />
        },
    ];

    return <>
        <FlexibleTable model={model}
                       columns={columns}
                       actions={(rec) => <Menu>
                           <Menu.Item key='cancel_blocking' onClick={() => cancelBlocking(rec.id)}
                                      disabled={rec.status === 'inactive'}>
                               Отменить блокировку
                           </Menu.Item>
                           <Menu.Item key='cancel_blocking'
                                      onClick={() => {
                                           setModalShow(true)
                                           setBlocking(rec)
                                       }}
                                      disabled={rec.status === 'inactive' || rec.btype.includes('аморозк')}>
                               Редактировать
                           </Menu.Item>
                       </Menu>}/>
        <BlockingModal targetObject={null}
                       visible={modalShow}
                       onCancel={() => setModalShow(false)}
                       blocking={blocking}
                       onSuccess={() => {
                           setModalShow(false);
                           toggleTableUpdate();
                       }}/>
    </>;

}
