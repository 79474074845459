import React, {useEffect, useState} from 'react'
import {Button, Select, Space, Typography} from "antd"
import {PlusCircleOutlined} from '@ant-design/icons';
import {get_id_ifnot} from "src/lib/tools";
import ContractService from "src/API/ContractAPI";
import ContractModal from "src/Components/Contragents/Modals/ContractModal";
import {useToggle} from "react-use";

import styles from './ContractSelect.module.less';

const Option = Select.Option;
const SHOW_NUMBER_LENGTH = 20;

export default function ContractSelect({
                                           contragent, seller, type,
                                           contracts: ctrs,
                                           onCreateButtonClick,
                                           addNewItem,
                                           showSellerName,
                                           showBuyerName,
                                           showType,
                                           showCreateButton,

                                           numberLength = SHOW_NUMBER_LENGTH,
                                           ...props
                                       }) {

    const [contracts, setContracts] = useState(ctrs || []);
    // const [contract, setContract] = useState(value);
    const [upd, toggleUpd] = useToggle(false);

    useEffect(() => {
        if (!ctrs) {
            if (contragent) {
                let params = {
                    seller,
                    type_contract: type,
                    buyer: get_id_ifnot(contragent)
                };
                if (!params.buyer) delete params.buyer;

                new ContractService(contragent?.id).listActive(params).then(r => {
                    setContracts(r.data.data);
                });

            } else if (ctrs) {
                setContracts(ctrs);
            }
        }
    }, [contragent, upd, seller, type]);

    // function _onChange(val) {
    //     setContract(val);
    //     onChange && onChange(val);
    // }

    // function _onSelect(val) {
    //     setContract(val);
    //     onSelect && onSelect(val);
    // }

    // function cut_length(str) {
    //     if (str?.length > (numberLength || SHOW_NUMBER_LENGTH)) {
    //         return `${str.substr(0, numberLength || SHOW_NUMBER_LENGTH)}...`;
    //     } else return str;
    // }

    function onBtnClick() {
        onCreateButtonClick && onCreateButtonClick();
    }

    // function showCtrData(ctr, show_buyer, show_seller, show_type) {
    //     let str = "";
    //     if (show_buyer)
    //         str += ctr.buyer_name;
    //     if (show_seller) {
    //         if (show_buyer) str += ' -> ';
    //         str += ctr.seller_name;
    //     }
    //     if (show_type)
    //         str += ` - ${ctr.get_type_display}`;
    //     return str;
    // }

    return (<>
        <Select notFoundContent={"Контракты не найдены"}
                //Не нужно это объявлять, {...porps} уже делает эти вещи. 
                //Иначе не работает редактирование(пустое поле)
                // onChange={_onChange}
                // onSelect={_onSelect}
                // value={contract?.id}
                className={styles.ContractSelect}
                {...props}>
            {addNewItem &&
                <Option value={null} key={null}>
                    <Space>
                        <Typography.Text style={{margin: 0}}>
                            Новый
                        </Typography.Text>
                    </Space>
                </Option>
            }
            {contracts.map((el) => (
                <Option value={el.id} key={el.id}>
                    <Space direction='vertical'>
                        <Typography.Text>
                            {/* {showCtrData(el, showBuyerName, showSellerName, showType)} */}
                            {`${el.seller.name} -> ${el.buyer.name}`}
                        </Typography.Text>
                        <Typography.Text type='secondary' copyable={{tooltips: ['скопировать номер контракта', 'скопировано']}}>
                            {el.number}
                        </Typography.Text>
                    </Space>
                </Option>
            ))}
        </Select>
        {showCreateButton &&
        <ContractCreateButton seller={seller}
                              onClick={onBtnClick}
                              type={type}
                              onAddContract={() => toggleUpd()}/>}
    </>)
}


function ContractCreateButton({visible, seller, onClick, onAddContract, type}) {
    const [modal_visible, setModalVisible] = useState(false);

    function _onClick() {
        setModalVisible(true);
        onClick && onClick();
    }

    function _onAddContract() {
        setModalVisible(false);
        onAddContract();
    }

    return <><Button type='primary'
                     onClick={_onClick}
                     icon={<PlusCircleOutlined/>}/>
        <ContractModal visible={modal_visible}
                       onCancel={() => setModalVisible(false)}
                       onSuccess={_onAddContract}
                       type={type}
                       seller={seller}/>
    </>;
}
