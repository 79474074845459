import BaseService, {error_handler} from "./BaseAPI";
import {AttributableService} from "./AttributableService";
import {notification} from "antd";

import axios from 'axios';


//TODO: сделать базовый класс AtolService, переопределить API, унаследовать остальные сервисы от него ПРАВИЛЬНО!

export default class AtolService extends AttributableService { //TODO: Переименовать в AtolContractService. Это сервис для работы с договорами
    MODEL = 'atol';

    new_contract(data) {
        return this._post('contract', data).then(res => {
            notification['success']({
                message: `Договор №${res.data.id} успешно создан`,
                description: 'Для просмотра перейдите в профиль контрагента в раздел "Договоры"'
            });
            return Promise.resolve(res);
        }).catch(AtolService.error_handler);
    }

    update(external_id, data) {
        return this._patch(`contract/${external_id}`, data).then(res => {
            notification['success']({
                message: 'Договор успешно отредактирован',
                description: 'Для просмотра перейдите в профиль контрагента в раздел "Договоры"'
            });
            return Promise.resolve(res);
        }).catch(AtolService.error_handler);
    }

}

export class AtolFullReportService extends BaseService {
    MODEL = 'atol/reports/full'

    status() {
        return this._get('status').then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

    run(){
        return this._get('run').then(res => {
            return Promise.resolve(res);
        }).catch(error_handler)
    }
}

export class AtolPService extends BaseService {

    constructor(){
        super()
        this.MODEL = 'atolpay'
    }

    check() {
        return this._get('check').catch(err => {
            error_handler(err);
            return Promise.reject(err);
        })
    }

}

export class AtolFullReportTransactionService extends BaseService {
    MODEL = 'atol/reports/full/transaction'

    status() {
        return this._get('status').then(res => {
            return Promise.resolve(res);
        }).catch(error_handler)
    }

    run(){
        return this._get('run').then(res => {
            return Promise.resolve(res);
        }).catch(error_handler)
    }
}

export class AtolTransactionReportService extends BaseService {
    MODEL = 'atol/reports/transaction'
}

export class AtolOrderItemReportService extends BaseService {
    MODEL = 'atol/reports/orderitem'
}

export class AtolNomenclatureReportService extends BaseService {
    MODEL = 'atol/reports/nomenclature'
}

export class AtolSubscribeReportService extends BaseService {
    MODEL = 'atol/reports/subscribe'
}

export class AtolInvoiceReportService extends BaseService {
    MODEL = 'atol/reports/invoice'
}
export class AtolContragentService extends BaseService{
    MODEL = 'atol/contragent'

    getAccount(){
        return this._get(`account`).then(res => {
            return Promise.resolve(res);
        }).catch(AtolService.error_handler);
    }

    createRefillMeter(data) {
        return this._post('meter', data).then(res => {
            notification['success']({
                message: `Заказ на пополнение счетчика создан`,
                description: 'Для просмотра перейдите в профиль контрагента в раздел "Заказы"'
            });
            return Promise.resolve(res);
        }).catch(AtolService.error_handler);
    }

    getMarket(){
        return this._get("market");
    }
}


export class AtolRevenueService extends AtolService {
    MODEL = 'atol/revenue-internal';

    get_documents() {
        return this._get('documents');
    }
}


export class AtolBlockingService extends AtolService {
    MODEL = 'atol/blocking';
}

export class AtolPayService extends BaseService{
    MODEL = 'atol/contragent'

    payUp(data){
        return this._post(`${data.contragent}/atolpay`, data).then(res => {
            return Promise.resolve(res);
        }).catch(AtolService.error_handler);
    }
}

export class AtolSubscribeService extends BaseService{
    MODEL = 'atol/subscribe'

    change_after_revenue_correction(data){
        return this._post(`change-after-revenue-correction`, data)
                   .catch(AtolService.error_handler);
    }
}

export class AtolSubscribeGroupService extends BaseService{
    MODEL = 'atol/subscribe/group'

    change_after_revenue_correction(data){
        return this._post(`change-after-revenue-correction`, data)
                   .catch(AtolService.error_handler);
    }
}


export class AtolImportService extends BaseService{
    MODEL = 'atol'

    get_csv(data) {
        const params = new URLSearchParams(data).toString();
        
        return this._get(`export/csv/?${params}`,  {
            responseType: 'blob',
        })
        
        .then(response => {
            // Если запрос успешен, создаем ссылку на blob
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'atolcaimportresults.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            // Обрабатываем ошибку, если запрос не удался
            console.error('Error fetching CSV:', error);
            this.constructor.error_handler(error); // Вызываем обработчик ошибок
        });
    }
}

