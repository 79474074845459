import {Button, Image, List, Modal, Space, Typography, message} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { AtolPayService } from "../../../API/IntegrationAPI";
import atolpay from '../../../img/atolpay_payment.svg'
import useIntegration from "../../../Providers/IntegrationsProvider";

export function PaymentModal({item, onCancel, ...props}) {

    const {integrations} = useIntegration();
    const [payments, setPayments] = useState([
        {key: 'priceplan', title: 'С Лицевого счета', disabled: true},
    ])
    
    useEffect(() => {
        let paymentSystems = [];
        if(integrations['atolpay.AtolPay']?.enable){
            paymentSystems.push(
                {key: 'atolpay.AtolPay', title: 'АТОЛ Пей', image: atolpay, disabled: false,
                    link: () => onPayAtolPay('link'),
                    pay: () => onPayAtolPay('pay')
                },
            )
        }
        setPayments([{key: 'priceplan', title: 'С Лицевого счета', disabled: true}, ...paymentSystems]);
        console.log(item);

        return () => {
            
        }
    }, [item])
    
    function onCancelPayment(){
        typeof onCancel === 'function' && onCancel();
    }

    function onPayAtolPay(type){
        console.log(item);

        new AtolPayService().register({
            document: item.id,
            return_url: window.location.href
        }).then(res => {
            if(res.data.status === 'paid'){
                message.success('Документ оплачен');
            }else{
                if(type === 'link'){
                    navigator.clipboard.writeText(res.data?.payment_url);
                    message.success('Скопировано');
                }else if(type === 'pay'){
                    window.location.href = res.data?.payment_url
                }
            }
        })
    }

    return (
        <Modal destroyOnClose title={`Выберите метод оплаты: ${item?.number}`}  footer={false} onCancel={onCancelPayment} {...props}>
            <List 
                bordered
                dataSource={payments}
                renderItem={el => (
                    <List.Item  extra={<Space>
                        <Button size='small' onClick={el?.pay} disabled={el.disabled}>К оплате</Button>
                        <Button size='small' onClick={el?.link} disabled={el.disabled} icon={<LinkOutlined />}></Button>
                    </Space>}>
                        <Space>
                            {el.image && <Image width={100} preview={false} src={el.image} />}
                            <Typography.Text>{el.title}</Typography.Text>
                        </Space>
                    </List.Item>
                )}
            />
        </Modal>
    );
}