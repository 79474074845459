import React, {useState} from "react";
import {Layout, Menu} from "antd";
import {Link, useLocation} from 'react-router-dom';
import {AppstoreOutlined} from '@ant-design/icons';
import useUser from "../../Providers/UserProvider";

import styles from './Layout.module.less';
import useIntegration from "../../Providers/IntegrationsProvider";

export default function Sider() {
    // const [collapsed, setCollapsed] = useState(false)
    const {contragent, is_global_permitted} = useUser();
    const {atol} = useIntegration();

    let location = useLocation();
    
    // function onCollapse(collapsed) {
    //     setCollapsed(collapsed);
    // }
    
    return (
      <Layout.Sider 
                    // collapsible
                    // collapsed={collapsed}
                    // onCollapse={onCollapse}
                    theme='light'
                    width={230}
                    className={styles.aside}>
          <Menu mode="inline" 
                defaultSelectedKeys={['/contragents']}
                selectedKeys={[location.pathname]}
          >
                <Menu.Item key={'/contragents/' + contragent.id + '/contracts'}>
                    <Link to={'/contragents/' + contragent.id + '/contracts'}>Договоры</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/documents'}>
                    <Link to={'/contragents/' + contragent.id + '/documents'}>Документы</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/orders'}>
                    <Link to={'/contragents/' + contragent.id + '/orders'}>Заказы</Link>
                </Menu.Item>
                {/* <Menu.Item key="/modules">
                    <Link to='/modules'>Интеграции</Link>
                </Menu.Item> */}
                <Menu.Item key={'/contragents/' + contragent.id + '/common'}>
                    <Link to={'/contragents/' + contragent.id + '/common'}>Карточка контрагента</Link>
                </Menu.Item>
                <Menu.Item key='/contragents'>
                    <Link to='/contragents'>Контрагенты</Link>
                </Menu.Item>
                {atol && 
                    <Menu.Item key="/reports">
                        <Link to='/reports'>Отчеты</Link>
                    </Menu.Item>
                }
                <Menu.Item key="/packages">
                    <Link to='/packages'>Пакеты</Link>
                </Menu.Item>
                {is_global_permitted('rules.rule.access') && 
                    <Menu.Item key="/rules">
                        <Link to='/rules'>Правила</Link>
                    </Menu.Item>
                }
                <Menu.Item key="/offers">
                    <Link to='/offers'>Предложения</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/products'}>
                    <Link to={'/contragents/' + contragent.id + '/products'}>Продукты</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/revenue'}>
                    <Link to={'/contragents/' + contragent.id + '/revenue'}>Реализации</Link>
                </Menu.Item>
                <Menu.Item key="/dictionary">
                    <Link to='/dictionary'>Справочники</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/discount'}>
                    <Link to={'/contragents/' + contragent.id + '/discount'}>Модификаторы цен</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/subscribes'}>
                    <Link to={'/contragents/' + contragent.id + '/subscribes'}>Услуги</Link>
                </Menu.Item>
                <Menu.Item key={'/contragents/' + contragent.id + '/mail'}>
                    <Link to={'/contragents/' + contragent.id + '/mail'}>Сообщения</Link>
                </Menu.Item>
                {is_global_permitted('application.template.access') &&
                    <Menu.Item key="/templates">
                        <Link to='/templates'>Шаблоны</Link>
                    </Menu.Item>
                }
          </Menu>
      </Layout.Sider>
    )
}
