import {EditOutlined, ReloadOutlined, CheckOutlined} from "@ant-design/icons";
import {Tooltip, Button, Spin, Col, Input, InputNumber, Row, Select, Space, Table, Tag} from "antd";
import SearchTable from "app/Base/Tables/SearchTable";
import {useEffect, useRef, useState} from "react";
import {render} from "react-dom";
import {Link} from "react-router-dom";
import {useEffectOnce, useToggle} from "react-use";
import {ContragentService} from "src/API/ContragentAPI";
import {OrderItemService} from "src/API/OrderAPI";
import {SubscribeService} from "src/API/ServiceAPI";
import Money, {NumberValue} from "../../Accounts/utils/Money";
import {DateTime} from "../../Common/OtherComponents";
import useContragent from "../ContragentContext";
import {notification} from "antd";
import moment from "moment";
import styles from  "./BillingTable.module.less";

function is_editable_status(record) {
    // console.log(record, res);
    return record && (
        record.revenue_status == 'template'
        || record.revenue_status == 'fake'
        || !record.revenue_status);
}

function GreenButton({onClick, ...props}) {
    return <Button icon={<CheckOutlined/>}
                   size={'small'}
                   className={styles.greenbutton}
                   onClick={onClick}
        {...props}
    />;
}

function NumberEditControl({children, value, record, onChange}) {
    const [edit, setEdit] = useState(false);
    const ref = useRef(null);
    const [_val, set_val] = useState(value);

    useEffect(() => {
        if (edit && ref.current)
            ref.current.focus();
    }, [edit]);

    function _onChange(val) {
        if (_val != value) {
            console.log(_val, typeof _val);
            if (typeof _val === "number")
                onChange?.(_val);
            else if (typeof _val === "string")
                onChange?.(parseFloat(_val.replace(",",".")));
        }
        setEdit(false);
    }

    return <Space direction={"horizontal"}>
        {(!edit) ? (value && children):
            <InputNumber //onBlur={() => setEdit(false)}
                         size={'small'}
                         parser={v => ((typeof v !== 'string')?v.toString():v).replace(',','.')}
                         className={styles.greenbuttoninput}
                         value={_val}
                         key={record.id}
                         addonAfter={<GreenButton onClick={_onChange}/>}
                         onPressEnter={_onChange}
                         onChange={set_val}
                         ref={ref}/>
        }
        {is_editable_status(record) &&
            <EditOutlined onClick={() => setEdit(!edit)} style={{marginLeft: "10px"}}/>}
    </Space>;
}

function DescriptionEditControl({children, value, record, onChange}) {
    const [edit, setEdit] = useState(false);
    const ref = useRef(null);
    const [local_val, setLocal_val] = useState(value);

    useEffect(() => {
        if (edit && ref.current)
            ref.current.focus();
    }, [edit]);

    function _onChange(val) {
        if (local_val != value)
            onChange?.(local_val);
        setEdit(false);
    }

    return <Space direction={"horizontal"}>
        {(!edit) ? <>
                {children}
            </> :
            <Input onBlur={() => setEdit(false)}
                   onPressEnter={_onChange}
                   value={local_val}
                   size={'small'}
                   onChange={e => setLocal_val(e.target.value)}
                   ref={ref}/>
        }
        {is_editable_status(record) &&
            <EditOutlined onClick={() => setEdit(!edit)} style={{marginLeft: "10px"}}/>}
    </Space>;
}


function RevenueSelectControl({rnum, onChange, record, service_id}) {
    const [edit, setEdit] = useState(false);
    const ref = useRef(null);
    const [revenues, setRevenues] = useState([]);
    const [revMap, setRevMap] = useState({});
    const {contragent} = useContragent();

    useEffect(() => {
        if (edit) {
            ref?.current?.focus?.();

            if (service_id) {
                new SubscribeService(contragent?.id, service_id).getAvailRevenues()
                    .then(r => {
                        const data = ([
                            {id: null, number: "- нет -"},
                            {id: 0, number: "- fake -"}
                        ]).concat(r.data.data);
                        setRevenues(data);
                        setRevMap(Object.fromEntries(data.map(x => [x.id, x])));
                    });
            }
        }
    }, [edit]);

    function _onChange(val) {
        const obj = revMap[val];
        onChange(obj);
        setEdit(false);
    }

    function revenue_number() {
        switch (record.revenue_status) {
            case "template":
                return <Tag>{rnum}</Tag>;
            case "active":
                return <Tag color={"green"}>{rnum}</Tag>;
            case "incorrect":
                return <Tag color={"yellow"}>{rnum}</Tag>;
            case "problem":
                return <Tag color={'volcano'}>{rnum}</Tag>;
            case 'fake':
                return <Tag color={"grey"}>fake:{rnum}</Tag>;
            case 'send_error':
                return <Tag color={"red"}>{rnum}</Tag>;
            default:
                return <Tag>{rnum}</Tag>;
        }
    }

    return <Space direction={"horizontal"}>
        {(!edit) ?
            <>{revenue_number()}</>:
        <Select onSelect={_onChange}
                value={rnum}
                onBlur={x => setEdit(false)}
                size={'small'}
                showSearch
                ref={ref}
        >
            {revenues?.map?.(x => (
                <Select.Option value={x.id} key={x.id}>
                    {x.number}
                </Select.Option>))}
        </Select>}
        {is_editable_status(record) &&
            <EditOutlined onClick={() => setEdit(!edit)} style={{marginLeft: "10px"}}/>}
    </Space>;
}


export default function BillingOrderTable({order_item, ...props}) {

    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});


    useEffectOnce(() => {
        new OrderItemService(order_item).history_list_counter().then(res => {
            setBillingList(res.data.data);
            setGeneralInfo({
                count: res.data?.count || 0,
                amount: res.data?.amount,
                taxes: res.data?.taxes,
                without_taxes: res.data?.without_taxes,
            });
        });

    }, [order_item]);

    const columns = [
        {title: "№", dataIndex: "id", width: 50},
        {
            title: "значение",
            dataIndex: "count",
        },
        {
            title: "сумма без НДС", dataIndex: "without_tax", render: (_, record) => (
                <Money sum={record.amount - record.taxes}/>
            ),
        },
        {
            title: "сумма", dataIndex: "amount", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },
        {
            title: "НДС", dataIndex: "taxes", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },
        {
            title: "дата внесения", dataIndex: "_created", width: 150, render: (dt) => {
                return <DateTime dt={dt}/>;
            },
        },
        {
            title: "утчен в расчетах?",
            dataIndex: "invoice_doc_id",
            key: "invoice_doc_id",
            render: (value) => {
                return value === null ? "Нет" : "Да";
            },
        },
        {
            title: "комментарий",
            dataIndex: "description",
            key: "description",
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey="id"
            size="small"
            summary={(_) => {

                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.count}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.without_taxes}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.amount}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.taxes}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            {...props}
        />
    );
}

export function SubscribePlanTable({service_item, ...props}) {
    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});
    const {contragent} = useContragent();

    const [search, setSearch] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [update,toggle] = useToggle();
    const [loading, setLoading] = useState(false);

    useEffectOnce(() => {
        setLoading(true);
        if (service_item && service_item?.id) {
            new SubscribeService(contragent?.id, service_item?.id).getPlan().then(res => {
                setBillingList(res.data.data);
                setFilteredList(res.data.data);
                setGeneralInfo({
                    total_amount: res.data?.total_amount,
                    total_billing_amount: res.data?.total_billing_amount,
                });
                setLoading(false);
            });
        }

    }, [service_item, update]);

    function prepare(val) {
        if (typeof val === "number")
            return val.toString().replace('.', ',');
        if (moment.isDate(val))
            return moment(val).format("DD.MM.YYYY");
        return val.toString();
    }

    function match(haystack, needle) {
        if (!haystack)
            return false;
        const v = prepare(haystack);
        return v.includes(needle);
    }

    useEffect(() => {
        // setLoading(true);
        if (!search)
            setFilteredList(billingList);
        else
            setFilteredList(billingList.filter(x => {
                // console.log(x.amount.toString(), x.date.toString());
                return match(x.amount, search) ||
                    match(x.billing_amount, search) ||
                    match(x.order_number,search) ||
                    match(x.revenue_number, search) ||
                    match(x.date, search) ||
                    match(x.description, search);
            }));
    }, [search]);

    // useEffect(() => {
    //     setLoading(false);
    // }, [filteredList]);

    function onChangeValue(value, field, plan_id) {
        new SubscribeService(contragent?.id, service_item?.id)
            .setPlanFactField(plan_id, {[field]: value})
            .then(r => {
                const newBL = billingList.map(x => {
                    if (x.id == plan_id)
                        x[field] = r.data[field];
                    return x;
                });
                setBillingList(newBL);
            }).catch(e => {
                notification.error({
                    message: "неправильное значение",
                    description: "Неправильное значение"
                })
        });
    }

    function onChangeRevenue(value, plan_id) {
        new SubscribeService(contragent?.id, service_item?.id)
            .setPlanFactField(plan_id, {revenue_id: value.id, revenue_number: value.number})
            .then(r => {
                const newBL = billingList.map(x => {
                    if (x.id == plan_id) {
                        x["revenue_number"] = r.data["revenue_number"];
                        x["revenue_status"] = r.data["revenue_status"];
                        x["billing_amount"] = r.data["billing_amount"];
                    }
                    return x;
                })
                setBillingList(newBL);
            })
            .catch(e => {
                notification.error({
                    message: "неправильная реализация",
                    description: "Выбрана неправильная реализация"
                })
            });
    }

    const columns = [
        {title: "№", dataIndex: "id", width: 50},
        {
            title: "Дата создания", dataIndex: "created", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "План", dataIndex: "amount", render: (sum, record) => (
                <NumberEditControl value={sum}
                                   record={record}
                                   onChange={value => onChangeValue(value, "amount", record.id)}>
                    <Money sum={sum} precision={4}/>
                </NumberEditControl>
            ),
        },
        {
            title: "Факт", dataIndex: "billing_amount", render: (sum, record) => (
                <NumberEditControl value={sum}
                                   record={record}
                                   onChange={value => onChangeValue(value, "billing_amount", record.id)}>
                    <Money sum={sum} precision={4}/>
                </NumberEditControl>
            ),
        },
        {
            title: "Значение", dataIndex: "count", width: 100, render: (cnt, rec) => (
                <NumberEditControl value={cnt}
                                   record={rec}
                                   onChange={value => onChangeValue(value, "count", rec.id)}>
                    <NumberValue value={cnt} precision={1}/>
                </NumberEditControl>
            ),
        },
        {title: 'Заказ', dataIndex: "order_id", render: (id, record) => {
            let ordnum = record.order_number;
            if (ordnum && ordnum.length > 20)
                ordnum = `${ordnum.substring(0,20)}...`;

            return <Tooltip title={record.order_number}>
                <Link to={`/contragents/${record.contragent_id}/orders/${id}`}>
                    {ordnum}
                </Link></Tooltip>;
        }},
        {
            title: "№ реализации", dataIndex: "revenue_number", render: (rnum, record) => (
                <RevenueSelectControl rnum={rnum} record={record}
                                      onChange={value => onChangeRevenue(value, record.id)}
                                      service_id={service_item?.id}
                />
            ),
        },
        {
            title: "Фактическая дата", dataIndex: "date", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "комментарий",
            dataIndex: "description",
            key: "description",
            render: (val, rec) => (
                <DescriptionEditControl value={val}
                                        record={rec}
                                        onChange={value => onChangeValue(value, "description", rec.id)}>
                    {val}
                </DescriptionEditControl>
            ),
        },
    ];

    return (
        // <Table
        <Spin spinning={loading}>
        <SearchTable
            columns={columns}
            // dataSource={billingList}
            dataSource={filteredList}
            onSearchEdit={setSearch}
            toggle={toggle}
            rowKey="id"
            size="small"
            summary={(_) => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell>-</Table.Summary.Cell>
                            <Table.Summary.Cell><Money
                                sum={parseFloat(generalinfo?.total_amount)}/></Table.Summary.Cell>
                            <Table.Summary.Cell><Money
                                sum={parseFloat(generalinfo?.total_billing_amount)}/></Table.Summary.Cell>
                            {/*<Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_tax)}/></Table.Summary.Cell>*/}
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            {...props}
        />
        </Spin>
    );
}

export function SubscribeDetalizationTable({service_item, ...props}) {
    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});
    const {contragent} = useContragent();

    useEffectOnce(() => {
        if (service_item && service_item?.id) {
            new SubscribeService(contragent?.id, service_item?.id).getDetalization().then(res => {
                setBillingList(res.data.data);
                setGeneralInfo({
                    total_amount: res.data?.total_amount,
                    // total_tax: res.data?.total_tax,
                });
            });
        }

    }, [service_item]);

    const columns = [
        {title: "№", dataIndex: "id", width: 50},
        {
            title: "сумма", dataIndex: "amount", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },

        {title: "Значение", dataIndex: "count", width: 100},
        {
            title: "Дата создания", dataIndex: "created", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "Фактическая дата", dataIndex: "date", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "комментарий",
            dataIndex: "description",
            key: "description",
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey="id"
            size="small"
            summary={(_) => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell><Money
                                sum={parseFloat(generalinfo?.total_amount)}/></Table.Summary.Cell>
                            {/*<Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_tax)}/></Table.Summary.Cell>*/}
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            {...props}
        />
    );
}

export function BillingCloseDayTable() {
    const {contragent} = useContragent();
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);


    const columns = [
        {title: "№", dataIndex: "id", key: "id"},
        {
            title: "дата биллинга", dataIndex: "date_billing", render: (dt) => {
                return <DateTime dt={dt}/>;
            },
        },
        {title: "описание", dataIndex: "description"},
        {
            title: "статус", dataIndex: "status", render: (_, record) => {

                switch (record.status) {
                    case 1:
                        return "в процессе";
                    case 2:
                        return "успешно";
                    case 3:
                        return "провален";
                }
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        new ContragentService(contragent?.id).listCloseDayBilling().then(res => {
            setList(res.data.data);
        }).finally(_ => {
            setLoading(false);
        });

    }, [contragent, update]);

    return (
        <Table
            title={() => (
                <Row justify="space-between">
                    <Col>

                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setUpdate(p => !p);
                            setLoading(true);
                        }}/>
                    </Col>
                </Row>
            )}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={list}
        />
    );
}
